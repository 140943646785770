import React, { useState } from 'react';

export interface KrpanoFullSizeProps {
  panoUrl?: string,
  onClose: () => void
}

export function KrpanoFullSize({ panoUrl, onClose }: KrpanoFullSizeProps) {
  const [showLoading, setShowLoading] = useState(true);

  const handleLoad = () => {
    setShowLoading(false);
  };

  return (
    <div className="krpano-full">
      <button type="button" className="btn btn-icon btn-dark text-white" onClick={onClose}>
        <span className="material-symbols-outlined">
          arrow_back
        </span>
      </button>
      {showLoading && <div className="krpano-loading">Cargando...</div>}
      <iframe
        loading="lazy"
        className="pano"
        id="recorridocostacuraco"
        title="Krpnao"
        width="auto"
        height="auto"
        onLoad={handleLoad}
        src={panoUrl || 'https://lanube360.com/tour-costacuraco/'}
      />
    </div>
  );
}
