import React from 'react';
import { InputProps, ObjectOfUnknownKeys } from '../../utils/types';

export interface Choice {
  name: string,
  value: string
}

interface ExtraData {
  choices: Choice[]
}

export interface SelectInputProps extends Omit<InputProps, 'extra_data'> {
  extra_data?: ObjectOfUnknownKeys | ExtraData,
}

export function SelectInput({
  name, label, id, extra_data = { choices: [] }, placeholder = '', onChange, value = '', read_only, help_text
}: SelectInputProps) {
  return (
    <div>
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <select disabled={read_only} value={value || ''} onChange={onChange as React.ChangeEventHandler<HTMLSelectElement>} name={name} id={id} className="form-select js-not-choices">
        {placeholder && <option value="">{placeholder}</option>}
        {
          (extra_data.choices as Choice[])
            .map((choice) => <option key={choice.value} value={choice.value}>{choice.name}</option>)
        }
      </select>
      <span className="text-muted">{help_text}</span>
    </div>
  );
}
