/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import qs from 'qs';
import { App } from '../../utils/app';
import { useMobile } from '../../utils/hooks';
import { FiltersForm } from './FiltersForm';
import { FiltersModal } from './FiltersModal';
import {
  ObjectOfUnknownKeys,
  ProjectDetailsInterface,
  InputProps,
  FormInterface,
  ProjectListResponse
} from '../../utils/types';

export interface FiltersProps extends FormInterface {
  onSearch?: (data: ProjectListResponse | null) => void,
  onLoading?: () => void,
  fields: InputProps[]
}

export const FiltersPropsFactory = (): FiltersProps => ({
  action: '',
  method: '',
  fields: []
});

export function Filters({
  action,
  method,
  fields,
  onSearch,
  onLoading
}: FiltersProps) {
  const [showFilters, setShowFilters] = useState(false);
  const formRefFilterForm = useRef<HTMLFormElement | null>(null);
  const formRefFilterModalForm = useRef<HTMLFormElement | null>(null);
  const formRefSearchForm = useRef<HTMLFormElement | null>(null);
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const isMobile = useMobile();

  const displayFiltersBox = () => {
    setShowFilters(true);
    if (isMobile) {
      App.Utils.hideAppOverflow();
    }
  };

  const hideFiltersBox = () => {
    setShowFilters(false);
    if (isMobile) {
      App.Utils.showAppOverflow();
    }
  };

  const toggleFilterBox = () => {
    if (showFilters) {
      hideFiltersBox();
    } else {
      displayFiltersBox();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchButtonRef.current) {
      App.Utils.removeLoading(searchButtonRef.current);
    }

    let userDataInputted: FormData = new FormData();

    if (!formRefFilterForm.current && formRefSearchForm.current) {
      userDataInputted = new FormData(formRefSearchForm.current);
    } else if (!formRefSearchForm.current) {
      if (formRefFilterForm.current && !isMobile) {
        userDataInputted = new FormData(formRefFilterForm.current);
      } else if (formRefFilterModalForm.current && isMobile) {
        userDataInputted = new FormData(formRefFilterModalForm.current);
      }
    } else if (formRefSearchForm.current) {
      const formDataAux = new FormData(formRefSearchForm.current);
      if (formRefFilterForm.current && !isMobile) {
        userDataInputted = new FormData(formRefFilterForm.current);
        userDataInputted.append('search_text', formDataAux.get('search_text') || '');
      } else if (formRefFilterModalForm.current && isMobile) {
        userDataInputted = new FormData(formRefFilterModalForm.current);
        userDataInputted.append('search_text', formDataAux.get('search_text') || '');
      }
    } else {
      return;
    }

    if (onLoading) {
      onLoading();
    }

    const dataToSend = {} as ObjectOfUnknownKeys;

    Array.from(userDataInputted.keys()).forEach((key) => {
      if (key.includes('min')) {
        const keyArray = key.split('[');
        if (userDataInputted.get(key) !== '') {
          dataToSend[keyArray[0]] = `{"min": ${userDataInputted.get(key)?.toString() || ''},`;
        }
      } else if (key.includes('max')) {
        const keyArray = key.split('[');
        if (userDataInputted.get(key) !== '') {
          dataToSend[keyArray[0]] = `${(dataToSend[keyArray[0]] as string)} "max": ${userDataInputted.get(key)?.toString() || ''}}`;
        }
      } else {
        dataToSend[key] = userDataInputted.get(key);
      }
    });

    (async () => {
      const queryParams = qs.stringify(dataToSend);
      const res = await fetch(`${action}?${queryParams.toString()}`, {
        method,
        headers: { 'Content-Type': 'application/json' }
      });

      if (res.ok) {
        const data = await res.json() as ProjectListResponse;
        if (onSearch) {
          onSearch(data);
        }
      } else {
        throw new Error('There has been an error');
      }
    })().catch((err) => {
      if (onSearch) {
        onSearch(null);
      }
      console.error(err);
    });
  };

  const handleModalSubmit = (e: React.FormEvent) => {
    // Like handleSubmit but also closes modal
    hideFiltersBox();
    return handleSubmit(e);
  };

  const showFiltersModal = (showFilters && isMobile);
  const showFiltersForm = (showFilters && !isMobile);

  return (
    // FiltersModal and FiltersForm are rendered always so they keep their state while closed.
    // "d-none" is used to hide them.
    <>
      {/* Filters for mobile: */}
      <FiltersModal
        formRef={formRefFilterModalForm}
        action={action}
        fields={fields}
        visible={showFiltersModal}
        onClose={hideFiltersBox}
        onSubmit={handleModalSubmit}
      />
      {/*
        FIXME: there's a bug with scrolling when isMobile is changed while showFilters==true
        Improvement: share state with the <FiltersForm> component rendered when !isMobile
      */}
      <Card className="card-filters">
        <Card.Body>
          <div>
            <form className="d-flex" onSubmit={handleSubmit} ref={formRefSearchForm}>
              <div className="input-group">
                <input
                  type="text"
                  name="search_text"
                  placeholder="Buscar..."
                  className="form-control"
                />
                <button
                  ref={searchButtonRef}
                  type="submit"
                  className="btn btn-icon btn-icon-lg"
                >
                  <span className="icon material-symbols-outlined">
                    search
                  </span>
                </button>

              </div>
              <button
                type="button"
                onClick={toggleFilterBox}
                className="btn btn-icon btn-icon-lg bg-primary ms-2"
              >
                <span className="icon material-symbols-outlined">
                  filter_alt
                </span>
              </button>
            </form>
          </div>
          {/* Filters for desktop: */}
          <div className={`mt-6 ${showFiltersForm ? '' : 'd-none'}`}>
            <FiltersForm
              formRef={formRefFilterForm}
              action={action}
              fields={fields}
              visible={showFiltersForm}
              onSubmit={handleSubmit}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
