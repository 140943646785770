import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ProjectDetailsInterface } from '../utils/types';
import { CardCTA } from '../components/CardCTA';
import { FavoriteShareBar } from '../components/FavoriteShareBar';
import { ImagesSlider, ImagesSliderProps } from '../components/ImagesSlider';
import { Map } from '../components/Map';
import { ProjectAccordion } from '../components/ProjectAccordion';
import { QuoteProjectForm, QuoteProjectFormProps, QuoteProjectFormPropsFactory } from '../components/QuoteProjectForm';
import { KrpanoBox } from '../components/krpano/KrpanoBox';
import { useUser } from '../providers/UserProvider';
import { ProjectControls } from '../components/ProjectControls';

export const ROOT_PROJECT_DETAILS_IDENTIFIER = '.react-root-project-details-view';

export interface ProjectDetailsViewProps {
  project: ProjectDetailsInterface,
  contact_form: QuoteProjectFormProps
}

export const ProjectDetailsViewPropsFactory = (): ProjectDetailsViewProps => ({
  project: {
    id: 0,
    name: '',
    description: '',
    latitude: 0,
    longitude: 0,
    highlighted: false,
    zone_id: '',
    region: '',
    commune: '',
    price: '',
    delivery: '',
    lake_access: false,
    river_access: false,
    car_access: false,
    water_access: '',
    electricity_access: '',
    internet_access: '',
    categories_ids: [],
    krpano_url: '',
    main_image: '',
    secondary_image: '',
    tertiary_image: '',
    is_favourite: false,
    owner_id: '',
    plot_management_url: '',
    beach_distance: null,
    town_distance: null
  },
  contact_form: { ...QuoteProjectFormPropsFactory() }
});

export function ProjectDetailsView({
  project,
  contact_form
}: ProjectDetailsViewProps) {
  const { userId } = useUser();
  const userIsOwner = userId && userId === project.owner_id;
  const goBack = () => {
    window.history.back();
  };

  const getProjectImages = (): ImagesSliderProps['images'] => {
    const allImages: ImagesSliderProps['images'] = [];
    if (project.main_image) {
      allImages.push(project.main_image);
    }
    if (project.secondary_image) {
      allImages.push(project.secondary_image);
    }
    if (project.tertiary_image) {
      allImages.push(project.tertiary_image);
    }

    return allImages;
  };

  return (
    <div className="pt-6 pb-7 pb-md-8">
      <div className="d-flex align-items-center mb-5">
        <button type="button" className="btn btn-icon me-3" onClick={goBack}>
          <span className="material-symbols-outlined">
            arrow_back
          </span>
        </button>
        <h1 className="h2 fw-semibold mb-0 ms-md-auto">
          {project.name}
        </h1>
        {/* Invisible element to center the title */}
        <span className="d-none d-md-block ms-md-auto" />
      </div>
      <KrpanoBox panoUrl={project.krpano_url} />
      {userIsOwner
        ? <ProjectControls projectId={project.id} plotManagementUrl={project.plot_management_url || ''} />
        : (
          <>
            <FavoriteShareBar
              shareDescription={project.description}
              shareTitle={project.name}
              shareUrl={window.location.href}
              projectId={project.id}
              markFavourite={project.is_favourite}
            />
            <div className="d-md-none">
              <a href={`/projects/${project.id}/request-quotation/`} className="d-block btn btn-primary mx-3">
                Cotizar proyecto
              </a>
            </div>
          </>
        )}
      <Row className="mt-6 mb-md-3">
        <Col md={8}>
          <section className="mb-6 mb-md-7">
            <h2 className="h6 fw-semibold mb-3">
              Ubicación
            </h2>
            <Map latitude={project.latitude} longitude={project.longitude} />
          </section>
          <ProjectAccordion project={project} />
          <section className="my-6 my-md-7">
            <h2 className="h6 fw-semibold mb-3">
              Imágenes
            </h2>
            <ImagesSlider images={getProjectImages()} />
          </section>
        </Col>
        {!userIsOwner && (
          <Col className="d-none d-md-block position-relative">
            <Card className="sticky-top-below-navbar">
              <Card.Body>
                <QuoteProjectForm
                  action={contact_form.action}
                  fields={contact_form.fields}
                  projectName={project.name}
                  projectPrice={project.price}
                  cancel_url={contact_form.cancel_url}
                  method={contact_form.method}
                  projectRegion={project.region}
                  commune={project.commune}
                />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <CardCTA />
        </Col>
      </Row>
    </div>
  );
}
