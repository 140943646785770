import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormProps, useForm } from '../hooks/useForm';
import { ObjectOfUnknownKeys } from '../utils/types';
import { useAlert } from '../providers/AlertsProvider';

export const ROOT_PROJECT_CREATE_IDENTIFIER = '.react-root-project-create-view';

export interface ProjectCreateProps {
  contact_form: FormProps
}

export const ProjectCreatePropsFactory = (): ProjectCreateProps => ({
  contact_form: {
    action: '',
    fields: [],
    method: ''
  }
});

export function ProjectCreateView({ contact_form }: ProjectCreateProps) {
  const { displayAlert } = useAlert();

  const customSubmit = (formData: ObjectOfUnknownKeys, crsfToken: string) => {
    const formDataMultiPart = new FormData();
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      formDataMultiPart.append(key, formData[key] as string);
    });

    const res = fetch(contact_form.action, {
      credentials: 'include',
      method: contact_form.method,
      headers: {
        'X-CSRFToken': crsfToken
      },
      body: formDataMultiPart
    });

    return res;
  };

  const {
    disableSubmit,
    getFields,
    onSubmit,
    showErrorMessage,
    setShowErrorMessage,
    showSuccessMessage,
    submitBtnRef
  } = useForm({
    action: contact_form.action,
    fields: contact_form.fields,
    method: contact_form.method,
    customSubmit
  });

  useEffect(() => {
    if (showErrorMessage) {
      displayAlert([{
        type: 'danger',
        message: 'Error al enviar la solicitud. Por favor inténtelo nuevamente.'
      }]);
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      displayAlert([{
        type: 'success',
        message: 'Su solicitud ha sido enviada correctamente. Nuestro equipo se pondrá en contacto.'
      }]);
    }
  }, [showSuccessMessage]);

  return (
    <div className="row">
      <div className="d-none d-md-block col-lg-3" />
      <div className="pt-5 pb-7 col-12 col-lg-6">
        <h1 className="mb-7 text-center">Publicar proyecto</h1>
        <Card>
          <Card.Body>
            <div>
              <form onSubmit={onSubmit}>
                <h2 className="h3 mb-6 text-center">Contáctanos para publicar tu proyecto</h2>
                {getFields()}
                <div className="d-md-inline-block">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 d-md-inline me-3 fw-medium"
                    disabled={disableSubmit}
                    ref={submitBtnRef}
                  >
                    Enviar solicitud
                  </button>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-none d-md-block col-lg-3" />
    </div>
  );
}
