import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputProps, ObjectOfUnknownKeys } from '../../utils/types';

interface ExtraData {
  'site-key': string
}

export interface CaptchaInputProps extends Omit<InputProps, 'extra_data'> {
  extra_data?: ObjectOfUnknownKeys | ExtraData
}

export function InputCaptcha({
  name = '',
  id = '',
  extra_data = { 'site-key': '' } as ExtraData,
  onChange
}: CaptchaInputProps) {
  const inputHiddenRef = useRef<HTMLInputElement | null>(null);
  const siteKey = extra_data['site-key'];

  const manuallyTriggerOnChangeEvent = (value: string) => {
    if (!Object || !window.HTMLInputElement.prototype) {
      console.error('No Object avaialable');
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
        .set.call(inputHiddenRef.current, value);

      inputHiddenRef.current!.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  const handleChange = (token: string | null) => {
    if (!token || !inputHiddenRef || !inputHiddenRef.current) {
      console.error('Error handling captcha');
    } else {
      manuallyTriggerOnChangeEvent(token);
    }
  };

  return (
    <div className="d-flex">
      <span className="mx-auto">
        <ReCAPTCHA
          sitekey={siteKey as string}
          size="normal"
          onChange={handleChange}
        />

        <input
          className="d-none"
          type="test"
          name={name}
          id={id}
          ref={inputHiddenRef}
          onChange={onChange}
        />
      </span>
    </div>
  );
}
