import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { ProjectCard } from './ProjectCard';
import { ProjectDetailsInterface } from '../utils/types';

export interface ProjectCardSliderProps {
  projects: ProjectDetailsInterface[],
  showFavoriteButton?: boolean | false
}

export function ProjectCardSlider({ projects, showFavoriteButton }: ProjectCardSliderProps) {
  return (
    <Swiper
      slidesPerView={projects.length ? 'auto' : 0}
      modules={[FreeMode]}
      freeMode
      loop
    >
      {projects.map((project) => (
        <SwiperSlide className="swiper-slide" key={`project-card-slide-item-${project.id}`}>
          <ProjectCard project={project} showFavoriteButton={showFavoriteButton} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
