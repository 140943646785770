import React, { useEffect } from 'react';
import { FormProps, useForm } from '../hooks/useForm';
import { formatNumberWithThousandSeparator } from '../utils/functions';
import { useAlert } from '../providers/AlertsProvider';

export interface QuoteProjectFormProps extends FormProps {
  projectName: string,
  projectPrice: string,
  projectRegion: string,
  commune: string,
  cancel_url: string,
}

export const QuoteProjectFormPropsFactory = (): QuoteProjectFormProps => ({
  projectName: '',
  projectPrice: '',
  projectRegion: '',
  commune: '',
  action: '',
  fields: [],
  cancel_url: '',
  method: ''
});

export function QuoteProjectForm({
  projectName, projectPrice, action, fields, cancel_url, projectRegion, commune
}: QuoteProjectFormProps) {
  const { displayAlert } = useAlert();

  const {
    disableSubmit,
    getFields,
    onSubmit,
    showErrorMessage,
    setShowErrorMessage,
    showSuccessMessage,
    submitBtnRef
  } = useForm({ action, fields });

  useEffect(() => {
    if (showErrorMessage) {
      displayAlert([{
        type: 'danger',
        message: 'Error al enviar la cotización. Por favor inténtelo nuevamente.'
      }]);
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      displayAlert([{
        type: 'success',
        message: '¡Cotización enviada con éxito!'
      }]);
    }
  }, [showSuccessMessage]);

  return (
    <div>
      <h3 className="fw-semibold mb-3">{projectName}</h3>
      <p className="fw-semibold mb-2">
        {projectPrice ? `Desde ${formatNumberWithThousandSeparator(projectPrice)}  UF` : 'Valor sin definir'}
      </p>
      <div className="d-flex mb-2">
        <dt>Región: </dt>
        <dd className="mb-0 ms-1">{projectRegion || ' Info no disponible'}</dd>
      </div>
      <div className="d-flex">
        <dt>Comuna: </dt>
        <dd className="mb-0 ms-1">{commune || ' Info no disponible'}</dd>
      </div>

      <form onSubmit={onSubmit} className="mt-6">

        {getFields()}

        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={disableSubmit}
          ref={submitBtnRef}
        >
          Cotizar proyecto
        </button>

        <a href={cancel_url} className="btn btn-outline-dark d-block my-5">Cancelar</a>
      </form>
    </div>
  );
}
