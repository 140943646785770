import React, { useState } from 'react';
import { InputProps } from '../../utils/types';

export function InputCheckbox({
  type, name, id, placeholder = '', label = '', onChange, value = ''
}: InputProps) {
  const [inputVal, setInputVal] = useState<string>(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value);
    onChange(e);
  };
  return (
    <div className="form-check">
      {label && <label htmlFor={name} className="form-check-label">{label}</label>}
      <input
        type="checkbox"
        name={name}
        id={id}
        placeholder={placeholder}
        className="form-check-input"
        onChange={handleChange}
        value={inputVal}
      />
    </div>
  );
}
