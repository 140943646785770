import React from 'react';
import { Card } from 'react-bootstrap';

export interface CardInfoProps {
  text: string;
  icon: string;
}

export function CardInfo({ text, icon }: CardInfoProps) {
  return (
    <Card className="card-info h-100">
      <Card.Body>
        <div className="card-info-icon">
          <img src={icon} alt="" />
        </div>
        <p className="card-info-text" style={{ flex: '1' }}>
          {text}
        </p>
      </Card.Body>
    </Card>
  );
}
