import React from 'react';
import { CardCTA } from '../components/CardCTA';
import { CardInfoGroup } from '../components/CardInfoGroup';
import { LocationSearchBox, LocationSearchBoxPropsFactory } from '../components/LocationSearchBox';
import { Carousel } from '../components/Carousel';
import { ProjectCardSlider } from '../components/ProjectCardSlider';
import { ProjectCardGrid } from '../components/ProjectCardGrid';
import { FormInterface, ProjectDetailsInterface, Slide } from '../utils/types';

export const ROOT_HOME_IDENTIFIER = '.react-root-home-view';

export interface HomeViewProps {
  contact_form: FormInterface,
  object_list: ProjectDetailsInterface[],
  slides: Slide[],
  highlights_title: string,
}

export const HomeViewPropsFactory = (): HomeViewProps => ({
  contact_form: { ...LocationSearchBoxPropsFactory() },
  object_list: [],
  slides: [],
  highlights_title: ''
});

export function HomeView({
  contact_form, object_list, slides, highlights_title
}: HomeViewProps) {
  return (
    <div>
      <Carousel slides={slides} />
      <section className="mt-2 mt-md-n8">
        <LocationSearchBox
          action={contact_form.action}
          fields={contact_form.fields}
          method={contact_form.method}
        />
      </section>
      <section className="mt-7 mt-md-8 text-center">
        <h2 className="text-center fw-semibold mb-md-7">{highlights_title}</h2>
        <div className="d-md-none">
          <ProjectCardSlider projects={object_list} />
        </div>
        <div className="d-none d-md-block">
          <ProjectCardGrid projects={object_list} />
        </div>
        <a href="/projects" className="btn btn-primary d-block d-md-inline-block mt-2">
          Ver todos los proyectos
        </a>
      </section>
      <section className="mt-7 mt-md-8">
        <h2 className="text-center fw-semibold mb-5 mb-md-7">Experiencia 360</h2>
        <CardInfoGroup />
      </section>

      <section className="my-7 my-md-8">
        <CardCTA />
      </section>
    </div>
  );
}
