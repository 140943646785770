import React from 'react';
import { Card } from 'react-bootstrap';
import { ProjectDetailsInterface } from '../utils/types';
import { formatNumberWithThousandSeparator } from '../utils/functions';
import { FavoriteButton } from './FavoriteButton';

interface ProjectCardProps {
  project?: ProjectDetailsInterface | null
  showFavoriteButton?: boolean | false
}

export function ProjectCard({ project = null, showFavoriteButton = false }: ProjectCardProps) {
  if (!project) {
    return (
      <Card>
        <Card.Body>
          Ocurrió un error. Por favor, recargue la página.
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className={`card card-project w-100 overflow-hidden ${project.highlighted ? 'card-featured' : ''}`}>
      {project.main_image
        ? (
          <div className="card-image">
            <div className="featured-label" />
            <img src={project.main_image} alt="" className="card-img-top" />
          </div>
        )
        : (
          <div className="card-image bg-secondary">
            <div className="featured-label" />
          </div>
        )}
      <div className="card-body text-start">
        <div>
          <h5 className="card-title mb-5">
            {project.name}
          </h5>

          <dl>
            <div className="d-flex gap-1">
              <dt>Región: </dt>
              <dd>{project.region || 'Info no disponible'}</dd>
            </div>
            <div className="d-flex gap-1">
              <dt>Comuna: </dt>
              <dd>{project.commune || 'Info no disponible'}</dd>
            </div>
            <div className="d-flex gap-1">
              <dt>Valor: </dt>
              <dd>
                {project.price ? `Desde ${formatNumberWithThousandSeparator(project.price)} UF` : 'Info no disponible'}
              </dd>
            </div>
          </dl>
        </div>

        <div className="d-flex justify-content-between">
          <a href={`/projects/${project.id}/`} className="d-block d-md-inline-block btn btn-outline-dark fw-medium">
            Ver más
          </a>
          {showFavoriteButton
            ? <FavoriteButton projectId={project.id} markFavourite={project.is_favourite} label="Favorito: " />
            : '' }
        </div>
      </div>
    </div>
  );
}
