import { ObjectOfUnknownKeys } from './types';

/**
 *
 * @param element - The variable to be evaluated
 * @returns boolean - True if the element is an object
 */
export const isAnObject = (element: any) => (
  typeof element === 'object'
  && !Array.isArray(element)
  && element !== null
);

/**
 *
 * @param object1
 * @param object2
 * @returns object - The new object with the same keys as object1,
 *                   but populated with the data from object2
 */

export const generatePopulatedObject = (
  object1: ObjectOfUnknownKeys,
  object2: ObjectOfUnknownKeys
) => {
  if (!object1 || !object2) {
    return {};
  }

  const object1Aux = { ...object1 };
  const object2Aux = { ...object2 };
  const objectResult = {} as ObjectOfUnknownKeys;
  Object.keys(object1Aux).forEach((key) => {
    objectResult[key] = object2Aux[key];
  });
  return { ...objectResult };
};

/**
 *
 * @param number
 * @returns string - Formated number with thousand separator
 */

export const formatNumberWithThousandSeparator = (number: string) => new Intl.NumberFormat('es-CL').format(Number(number));
