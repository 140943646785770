import React from 'react';
import { ProjectCardGrid } from '../components/ProjectCardGrid';
import { ProjectCardSlider } from '../components/ProjectCardSlider';
import { useUser } from '../providers/UserProvider';
import { ProjectDetailsInterface } from '../utils/types';

export const USER_DETAILS_IDENTIFIER = '.react-root-user-detail-view';

export interface UserDetailsViewProps {
  user: {
    favourite_projects: ProjectDetailsInterface[],
    owned_projects?: ProjectDetailsInterface[],
    first_name: string,
    id: number,
    last_name: string
  }
}

export const UserDetailsViewPropsFactory = (): UserDetailsViewProps => ({
  user: {
    favourite_projects: [],
    owned_projects: undefined,
    first_name: 'first_name',
    id: 0,
    last_name: 'last_name'
  }
});
export function UserDetailsView({ user }: UserDetailsViewProps) {
  const { initials } = useUser();
  return (
    <div className="row mb-7 mb-md-8">
      <div className="col-0 col-lg-1" />
      <div className="col-12 col-lg-10">
        <div className="d-flex flex-column flex-md-row justify-content-between py-6 py-md-5 border-bottom mb-5 mb-md-6">
          <div className="d-flex flex-md-row flex-column align-items-center">
            <div
              className="center-all rounded-circle bg-dark text-white fs-1"
              style={{
                width: '159px', height: '159px'
              }}
            >
              {initials}
            </div>
            <div className="pt-6 ms-0 ms-md-6">
              <h1 className="mb-3 fs-2">{`${user.first_name} ${user.last_name}`}</h1>
              <div className="d-flex">
                {user.owned_projects && (
                  <div className="me-2 me-md-3">
                    <span className="d-block d-md-inline-block fs-5 fw-bold ms-5 ms-md-0 ps-1 ps-md-0">{user.owned_projects.length}</span>
                    <span className="d-block d-md-inline-block ms-md-1">
                      {user.owned_projects.length === 1 ? 'Proyecto' : 'Proyectos'}
                    </span>
                  </div>
                )}
                <div>
                  <span className="d-block d-md-inline-block fs-5 fw-bold ms-5 ms-md-0 ps-1 ps-md-0">{user.favourite_projects.length}</span>
                  <span className="d-block d-md-inline-block ms-md-1">
                    {user.favourite_projects.length === 1 ? 'Favorito' : 'Favoritos'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <a href="/accounts/edit/" className="d-block d-md-inline-block btn btn-outline-secondary">Editar perfil</a>
          </div>
        </div>
        {user.owned_projects && (
          <div className="mb-7 mb-md-8">
            <h2 className="fs-5 mb-md-5">Mis proyectos</h2>
            <div className="d-md-none">
              <ProjectCardSlider projects={user.owned_projects} />
            </div>
            <div className="d-none d-md-block">
              <ProjectCardGrid
                projects={user.owned_projects}
                maxProjects={3}
                xlWidthByCard={4}
              />
            </div>
            <div className="text-center">
              {(user.owned_projects.length > 0) && (
                <a href="/projects/my-projects" className="btn btn-outline-secondary d-block d-md-inline-block mt-3">
                  Ver todos
                </a>
              )}
            </div>

            {!(user.owned_projects.length > 0) && (
              <p>No hay proyectos para mostrar</p>
            )}
            <div className="col-0 col-lg-1" />
          </div>
        )}
        <div>
          <h2 className="fs-5 mb-md-5">Favoritos</h2>
          <div className="d-md-none">
            <ProjectCardSlider projects={user.favourite_projects} showFavoriteButton />
          </div>
          <div className="d-none d-md-block">
            <ProjectCardGrid
              projects={user.favourite_projects}
              maxProjects={3}
              xlWidthByCard={4}
              showFavoriteButton
            />
          </div>
          <div className="text-center">
            {(user.favourite_projects.length > 0) && (
              <a href="/projects/my-favourite-projects/" className="btn btn-outline-secondary d-block d-md-inline-block mt-3">
                Ver todos
              </a>
            )}
            {!(user.favourite_projects.length > 0) && (
              <p>No hay favoritos para mostrar</p>
            )}
          </div>
          <div className="col-0 col-lg-1" />
        </div>
      </div>
      <div className="col-0 col-lg-1" />
    </div>
  );
}
