import React from 'react';

export interface ProjectControlsProps {
  projectId: string | number,
  plotManagementUrl: string,
}

export function ProjectControls({ projectId, plotManagementUrl }: ProjectControlsProps) {
  return (
    <div className="d-flex flex-column flex-md-row-reverse justify-content-center my-5 py-3 border-top border-bottom">
      <a href={plotManagementUrl} target="_blank" className="btn btn-outline-dark mb-3 mb-md-0 ms-md-3" rel="noreferrer">
        Ir al administrador de lotes
      </a>
      <a href={`/projects/${projectId}/update/`} className="btn btn-primary">Editar proyecto</a>
    </div>
  );
}
