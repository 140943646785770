import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormProps, useForm } from '../hooks/useForm';
import { useAlert } from '../providers/AlertsProvider';
import { AlertProps } from '../utils/types';

export const ROOT_LOGIN_IDENTIFIER = '.react-root-login-view';

export interface LoginViewProps {
  contact_form: FormProps
}

export const LoginPropsFactory = (): LoginViewProps => ({
  contact_form: {
    action: '',
    fields: [],
    method: ''
  }
});

export function LoginView({ contact_form }: LoginViewProps) {
  const { displayAlert } = useAlert();

  const {
    disableSubmit,
    getFields,
    onSubmit,
    showErrorMessage,
    setShowErrorMessage,
    errorMessagesTexts,
    submitBtnRef
  } = useForm({
    action: contact_form.action,
    fields: contact_form.fields,
    method: contact_form.method,
    urlSuccess: '/projects'
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailConfirmationParam = urlParams.get('email_confirmation');
    if (emailConfirmationParam === 'success') {
      displayAlert([{
        type: 'success',
        message: 'Cuenta activada exitosamente, ya puedes usar la aplicación'
      }]);
    }
  }, []);

  const handleFormMessage = (messages: string[]) => {
    const alertsData: AlertProps[] = messages.map((message) => ({
      type: 'danger',
      message
    }));

    displayAlert(alertsData);
  };

  useEffect(() => {
    if (showErrorMessage) {
      if (errorMessagesTexts) {
        Object
          .keys(errorMessagesTexts)
          .forEach((key) => handleFormMessage(errorMessagesTexts[key]));
      } else {
        displayAlert([{
          type: 'danger',
          message: 'Error al iniciar sesión. Por favor inténtelo nuevamente.'
        }]);
      }
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  return (
    <div className="row pt-7 pt-md-9">
      <div className="d-none d-md-block col-lg-3" />
      <div className="col-12 col-lg-6">
        <Card>
          <Card.Body>
            <div>
              <h1 className="mb-6 text-center">Iniciar sesión</h1>
              <form onSubmit={onSubmit} className="mt-2">

                {getFields()}
                <div className="mb-5 mt-0 mt-md-n2">
                  <a className="fw-semibold text-body" href="/accounts/password-reset/">¿Olvidaste tu contraseña?</a>
                </div>
                <div className="d-block d-md-inline-block mb-5">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 fw-medium"
                    disabled={disableSubmit}
                    ref={submitBtnRef}
                  >
                    Iniciar sesión
                  </button>
                </div>
                <div>
                  <span>
                    ¿No tienes Sesión?,
                    <a className="fw-semibold text-body ms-1" href="/accounts/register/">Regístrate aquí.</a>
                  </span>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-none d-md-block col-lg-3" />
    </div>
  );
}
