import React, { useState, useEffect } from 'react';
import { InputProps } from '../../utils/types';

export function Textarea({
  name, id, placeholder = '', label = '', onChange, value = '', read_only, help_text
}: InputProps) {
  const [inputVal, setInputVal] = useState<string>(value);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputVal(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  return (
    <div>
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <textarea
        name={name}
        id={id}
        placeholder={placeholder}
        className="form-control"
        onChange={handleChange}
        value={inputVal}
        readOnly={read_only}
        tabIndex={read_only ? -1 : undefined}
      />
      <span className="text-muted">{help_text}</span>
    </div>
  );
}
