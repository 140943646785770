import React from 'react';
import BsAccordion from 'react-bootstrap/Accordion';
import { ProjectDetailsInterface } from '../utils/types';
import { formatNumberWithThousandSeparator } from '../utils/functions';

export interface ProjectAccordionProps {
  project: ProjectDetailsInterface
}

export function ProjectAccordion({ project }: ProjectAccordionProps) {
  return (
    <BsAccordion defaultActiveKey={`${project.description ? '0' : '1'}`}>
      {project.description
        && (
          <BsAccordion.Item eventKey="0">
            <BsAccordion.Header>Descripción</BsAccordion.Header>
            <BsAccordion.Body>{project.description}</BsAccordion.Body>
          </BsAccordion.Item>
        )}

      <BsAccordion.Item eventKey="1">
        <BsAccordion.Header>Valor</BsAccordion.Header>
        <BsAccordion.Body>{project.price ? `Desde ${formatNumberWithThousandSeparator(project.price)} UF` : 'Sin definir'}</BsAccordion.Body>
      </BsAccordion.Item>

      <BsAccordion.Item eventKey="2">
        <BsAccordion.Header>Entrega</BsAccordion.Header>
        <BsAccordion.Body>{project.delivery || 'Sin definir'}</BsAccordion.Body>
      </BsAccordion.Item>

      <BsAccordion.Item eventKey="3">
        <BsAccordion.Header>Accesos</BsAccordion.Header>
        <BsAccordion.Body>
          <ul>
            <li>
              <span className="fw-semibold">Acceso a lago: </span>
              {project.lake_access ? 'Sí' : 'No'}
            </li>
            <li>
              <span className="fw-semibold">Acceso a río: </span>
              {project.river_access ? 'Sí' : 'No'}
            </li>
            <li>
              <span className="fw-semibold">Acceso vehicular: </span>
              {project.car_access ? 'Sí' : 'No'}
            </li>
            <li>
              <span className="fw-semibold">Cercanía a playa: </span>
              {project.beach_distance === null
               ? 'Info no disponible'
               : `${project.beach_distance} km`}
            </li>
            <li>
              <span className="fw-semibold">Cercanía a centro urbano: </span>
              {project.town_distance === null
               ? 'Info no disponible'
               : `${project.town_distance} km`}
            </li>
          </ul>
        </BsAccordion.Body>
      </BsAccordion.Item>
      <BsAccordion.Item eventKey="4">
        <BsAccordion.Header>Servicios básicos</BsAccordion.Header>
        <BsAccordion.Body>
          <ul>
            <li>
              <span className="fw-semibold">Acceso a agua: </span>
              {project.water_access || 'Info no disponible'}
            </li>
            <li>
              <span className="fw-semibold">Acceso a electricidad: </span>
              {project.electricity_access || 'Info no disponible'}
            </li>
            <li>
              <span className="fw-semibold">Acceso a internet: </span>
              {project.internet_access || 'Info no disponible'}
            </li>
          </ul>
        </BsAccordion.Body>
      </BsAccordion.Item>
    </BsAccordion>
  );
}
