import React, { useEffect, useState, useRef } from 'react';
import { App } from '../utils/app';
import { inputGenerator } from '../components/inputs';
import {
  BaseInputProps, FormInterface, InputChangeEvent, ObjectOfUnknownKeys
} from '../utils/types';

export interface FormProps extends FormInterface {
  customSubmit?: (formData: ObjectOfUnknownKeys, crsfToken: string) => Promise<Response>,
  urlSuccess?: string
}

interface ErrorMessages {
  [key: string]: string[]
}

interface FieldsOptions {
  leftCaptcha: boolean
}

export function useForm({
  action, fields, method, customSubmit, urlSuccess
}: FormProps) {
  const [formData, updateFormData] = useState<ObjectOfUnknownKeys>({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessagesTexts, setErrorMessageText] = useState<ErrorMessages | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  const allRequiredFieldsHaveText = (requiredFields: BaseInputProps[]) => {
    const dataKeys = Object.keys(formData);
    return !dataKeys.some((key) => (formData[key] as string)
      .toString().trim() === '' && requiredFields.find((field) => field.name === key)?.required);
  };

  const handleSubmitButton = () => {
    const formDataSize = Object.keys(formData).length;
    const requiredFields = fields.filter((field) => field.required === true);
    if (formDataSize >= requiredFields.length && allRequiredFieldsHaveText(requiredFields)) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  useEffect(handleSubmitButton, [formData]);

  const setDefaultFormValues = () => {
    const defaultFormData = {} as ObjectOfUnknownKeys;
    fields.forEach((field) => {
      defaultFormData[field.name] = field.value?.toString() || '';
    });

    updateFormData(defaultFormData);
  };

  useEffect(setDefaultFormValues, []);

  const handleChange = (e: InputChangeEvent) => {
    updateFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const getFields = (options?: FieldsOptions) => (fields.map((field) => {
    let wrapperClasses = 'mb-3';

    if (options) {
      wrapperClasses = options.leftCaptcha && field.type === 'recaptcha-v2' ? wrapperClasses.concat(' d-flex') : wrapperClasses;
    }

    return (
      <div key={field.name} className={wrapperClasses}>
        {inputGenerator({
          ...field,
          value: formData[field.name] as string,
          onChange: handleChange
        })}
      </div>
    );
  }));

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowErrorMessage(false);
    setShowSuccessMessage(false);

    const crsfToken = formData.csrf_token as string;
    (async () => {
      // TODO: Add invalid input feedback validation. Move form functionality to
      // its own file, maybe using react-form

      let res: Response;
      if (customSubmit) {
        res = await customSubmit(formData, crsfToken);
      } else {
        res = await fetch(action, {
          credentials: 'include',
          method: method || 'POST',
          headers: {
            'X-CSRFToken': crsfToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
      }

      if (!res.ok) {
        const errResponse = await res.json() as ErrorMessages;
        setErrorMessageText(errResponse);
        setShowErrorMessage(true);
      } else {
        setShowSuccessMessage(true);
        if (urlSuccess) {
          setTimeout(() => {
            window.location.href = urlSuccess;
          }, 1500);
        }
      }

      if (submitBtnRef.current) {
        App.Utils.removeLoading(submitBtnRef.current);
      }
    })().catch((err) => {
      console.error(err);
      setShowErrorMessage(true);
    });
  };

  return {
    disableSubmit,
    showErrorMessage,
    setShowErrorMessage,
    errorMessagesTexts,
    showSuccessMessage,
    onSubmit,
    getFields,
    submitBtnRef,
    formData
  };
}
