import React from 'react';
import { FiltersForm, FiltersFormProps } from './FiltersForm';

export interface FiltersModalProps extends FiltersFormProps {
  onClose: () => void;
}

export function FiltersModal({
  formRef,
  action,
  fields,
  visible,
  onClose,
  onSubmit
}: FiltersModalProps) {
  return (
    <div
      id="filter-modal"
      className={`fixed-top bg-light overflow-auto vh-100 ${visible ? '' : 'd-none'}`}
    >
      <div className="bg-primary p-3 d-flex justify-content-between align-items-center">
        <button type="button" className="btn btn-icon bg-white" onClick={onClose}>
          <span className="icon material-symbols-outlined">
            arrow_back
          </span>
        </button>
        <h2 className="mb-0 fw-semibold">Filtros</h2>
        {/* Invisible element to center the title */}
        <span />
      </div>
      <div className="p-3">
        <FiltersForm
          action={action}
          fields={fields}
          formRef={formRef}
          visible={visible}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}
