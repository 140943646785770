import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormProps, useForm } from '../hooks/useForm';
import { useAlert } from '../providers/AlertsProvider';
import { AlertProps } from '../utils/types';

export const ROOT_REGISTER_IDENTIFIER = '.react-root-register-view';

export interface RegisterViewProps {
  contact_form: FormProps
}

export const RegisterPropsFactory = (): RegisterViewProps => ({
  contact_form: {
    action: '',
    fields: [],
    method: ''
  }
});

export function RegisterView({ contact_form }: RegisterViewProps) {
  const { displayAlert } = useAlert();

  const {
    disableSubmit,
    getFields,
    onSubmit,
    showErrorMessage,
    showSuccessMessage,
    errorMessagesTexts,
    setShowErrorMessage,
    submitBtnRef,
    formData
  } = useForm({
    action: contact_form.action,
    fields: contact_form.fields,
    method: contact_form.method
  });

  const handleFormMessage = (messages: string[]) => {
    const alertsData: AlertProps[] = messages.map((message) => ({
      type: 'danger',
      message
    }));

    displayAlert(alertsData);
  };

  useEffect(() => {
    if (showErrorMessage) {
      if (errorMessagesTexts) {
        Object
          .keys(errorMessagesTexts)
          .forEach((key) => handleFormMessage(errorMessagesTexts[key]));
      } else {
        displayAlert([{
          type: 'danger',
          message: 'Error al registrarse. Por favor inténtelo nuevamente.'
        }]);
      }
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  const getEmail = () => formData.email as string;

  useEffect(() => {
    if (showSuccessMessage) {
      displayAlert([{
        type: 'success',
        message: `Se ha envíado un correo de validación a esta dirección ${getEmail()}.`
      }]);
    }
  }, [showSuccessMessage]);

  return (
    <div className="row pt-7 pt-md-9 pb-md-8">
      <div className="d-none d-md-block col-lg-3" />
      <div className="col-12 col-lg-6">
        <Card>
          <Card.Body>
            <div>
              <h1 className="mb-6 text-center">Registro</h1>
              <form onSubmit={onSubmit} className="mt-2">

                {getFields()}
                <div className="d-block d-md-inline-block mb-5 mt-5 mt-md-2">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 fw-medium"
                    disabled={disableSubmit}
                    ref={submitBtnRef}
                  >
                    Registrarse
                  </button>
                </div>
                <div>
                  <span>
                    Si ya tienes cuenta,
                    <a className="fw-semibold text-body ms-1" href="/accounts/login">ingresa aquí.</a>
                  </span>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-none d-md-block col-lg-3" />
    </div>
  );
}
