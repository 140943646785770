import React, { PropsWithChildren } from 'react';
import { FormInterface } from '../utils/types';

import { createSafeContext } from '../utils/useSafeContext';

export interface SubscriptionFormConsumerProps extends PropsWithChildren {
  form: FormInterface
}

const [useContext, Provider] = createSafeContext<SubscriptionFormConsumerProps>();

export function SubscriptionFormProvider({
  children, form
}: SubscriptionFormConsumerProps) {
  const providerValues: SubscriptionFormConsumerProps = {
    form
  };

  return (
    <Provider value={providerValues}>
      {children}
    </Provider>
  );
}

export const useSubscriptionForm = useContext;
