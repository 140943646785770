import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormProps, useForm } from '../hooks/useForm';
import { ObjectOfUnknownKeys } from '../utils/types';
import { useAlert } from '../providers/AlertsProvider';

export const ROOT_PROJECT_UPDATE_IDENTIFIER = '.react-root-project-update-view';

export interface ProjectUpdateProps {
  contact_form: FormProps
}

export const ProjectUpdatePropsFactory = (): ProjectUpdateProps => ({
  contact_form: {
    action: '',
    fields: [],
    method: ''
  }
});

export function ProjectUpdateView({ contact_form }: ProjectUpdateProps) {
  const { displayAlert } = useAlert();

  const customSubmit = (formData: ObjectOfUnknownKeys, crsfToken: string) => {
    const formDataMultiPart = new FormData();
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      if (key === 'main_image' || key === 'secondary_image' || key === 'tertiary_image') {
        if (typeof (formData[key] as string) !== 'string') {
          formDataMultiPart.append(key, formData[key] as string);
        }
      } else {
        formDataMultiPart.append(key, formData[key] as string);
      }
    });

    const res = fetch(contact_form.action, {
      credentials: 'include',
      method: contact_form.method,
      headers: {
        'X-CSRFToken': crsfToken
      },
      body: formDataMultiPart
    });

    return res;
  };

  const {
    disableSubmit,
    getFields,
    onSubmit,
    showErrorMessage,
    setShowErrorMessage,
    showSuccessMessage,
    submitBtnRef
  } = useForm({
    action: contact_form.action,
    fields: contact_form.fields,
    method: contact_form.method,
    urlSuccess: `/projects/${window.location.pathname.split('/')[2]}`,
    customSubmit
  });

  const fieldsWithSubTitles = () => {
    const fields = getFields();
    fields.splice(0, 0, (<h5 key="0" className="fw-semibold mb-3">Información general</h5>));
    fields.splice(5, 0, (<h5 key="5" className="fw-semibold mb-3 mt-2">Entrega</h5>));
    fields.splice(7, 0, (<h5 key="7" className="fw-semibold mb-3 mt-2">Accesos</h5>));
    fields.splice(13, 0, (<h5 key="13" className="fw-semibold mb-3 mt-2">Servicios Básicos</h5>));
    fields.splice(17, 0, (<h5 key="17" className="fw-semibold mb-3 mt-2">Imágenes</h5>));
    return fields;
  };

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    if (showErrorMessage) {
      displayAlert([{
        type: 'danger',
        message: 'Error al editar el proyecto. Por favor inténtelo nuevamente.'
      }]);
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      displayAlert([{
        type: 'success',
        message: '¡Proyecto actualizado correctamente!'
      }]);
    }
  }, [showSuccessMessage]);

  return (
    <div className="row">
      <div className="d-none d-md-block col-lg-3" />
      <div className="pt-5 pb-7 col-12 col-lg-6">
        <h1 className="mb-7 text-center">Editar proyecto</h1>
        <Card>
          <Card.Body>
            <div>
              <form onSubmit={onSubmit} className="mt-2">

                {fieldsWithSubTitles()}
                <div className="d-none d-md-inline-block">
                  <button
                    type="submit"
                    className="btn btn-primary d-md-inline me-3 fw-medium"
                    disabled={disableSubmit}
                    ref={submitBtnRef}
                  >
                    Guardar cambios
                  </button>
                  <button
                    onClick={handleGoBack}
                    className="btn btn-outline-dark d-none d-md-inline fw-medium"
                    type="button"
                  >
                    Cancelar
                  </button>
                </div>
                <div className="d-md-none">
                  <button
                    type="submit"
                    className="btn btn-primary fw-medium w-100"
                    disabled={disableSubmit}
                    ref={submitBtnRef}
                  >
                    Guardar cambios
                  </button>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-none d-md-block col-lg-3" />
    </div>
  );
}
