import React from 'react';
import { useUser } from '../providers/UserProvider';
import { useMobile } from '../utils/hooks';
import { NavLink } from './NavLink';

export function Navbar() {
  const isMobile = useMobile('992px'); // Bootstrap lg breakpoint
  const { isAuthenticated, initials } = useUser();

  const getUserLinks = () => {
    const profilePath = '/accounts/profile/';
    const logoutPath = '/accounts/logout/';

    if (isMobile) {
      return (
        <>
          <NavLink path={profilePath} text="Ver perfil" />
          <NavLink path={logoutPath} text="Cerrar sesión" />
        </>
      );
    }

    return (
      <li className="nav-item ms-2 btn-group">
        <button
          className="dropdown nav-link nav-link-icon"
          style={{ backgroundColor: 'transparent', fontSize: '1rem' }}
          type="button"
          id="profile-button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {initials}
        </button>
        <ul className="dropdown-menu" aria-labelledby="profile-button" style={{ left: '-115px' }}>
          <li><a className="dropdown-item" href={profilePath}>Ver perfil</a></li>
          <li><a className="dropdown-item" href={logoutPath}>Cerrar sesión</a></li>
        </ul>
      </li>
    );
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white fixed-top">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/static/logo-5.png" alt="Logo de La Nube 360" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMobileLinks"
          aria-controls="navbarMobileLinks"
          aria-expanded="false"
          aria-label="Mostrar navegación"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarMobileLinks">
          <ul className="navbar-nav mb-1 mb-md-0">
            <NavLink path="/" text="Inicio" checkActive={!isMobile} />
            <NavLink path="/projects/" text="Proyectos" checkActive={!isMobile} />
            <NavLink path="/projects/create" text="Publicar Proyecto" btnStyle={!isMobile} />
            {isAuthenticated
              ? getUserLinks()
              : <NavLink path="/accounts/login/" text="Inicio Sesión" iconStyle={!isMobile} />}
          </ul>
        </div>
      </div>
    </nav>
  );
}
