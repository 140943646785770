import React, { useEffect } from 'react';
import { useForm } from '../hooks/useForm';
import { useSubscriptionForm } from '../providers/SubscriptionFormProvider';
import { useAlert } from '../providers/AlertsProvider';

export function SubscriptionForm() {
  const { displayAlert } = useAlert();

  const { form } = useSubscriptionForm();
  const {
    getFields,
    submitBtnRef,
    onSubmit,
    showErrorMessage,
    showSuccessMessage,
    setShowErrorMessage,
    disableSubmit
  } = useForm({
    action: form.action,
    fields: form.fields,
    method: form.method
  });

  useEffect(() => {
    if (showErrorMessage) {
      displayAlert([{
        type: 'danger',
        message: 'Ocurrió un error. Por favor inténtelo nuevamente.'
      }]);
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      displayAlert([{
        type: 'success',
        message: '¡Subscripción exitosa!'
      }]);
    }
  }, [showSuccessMessage]);

  return (
    <form onSubmit={onSubmit} className="d-flex flex-column">
      {getFields({ leftCaptcha: true })}
      <button type="submit" className="btn btn-light me-lg-auto" ref={submitBtnRef} disabled={disableSubmit}>
        Enviar
      </button>
    </form>
  );
}
