import React from 'react';
import { CardInfo } from './CardInfo';

export function CardInfoGroup() {
  return (
    <div className="row row-cols-1 row-cols-md-3 g-3 g-md-6">
      <div className="col">
        <CardInfo
          text="Busca tu proyecto por región o comuna y disfruta de la experiencia 360º. Haz click en Proyectos y podrás acceder a filtros para búsqueda avanzada."
          icon="/static/icons/landscape-icon.svg"
        />
      </div>
      <div className="col">
        <CardInfo
          text={`Recorre virtualmente los proyectos y sus entornos.
          Haz click en los íconos y averiguarás sobre su disponibilidad, precio, superficie, etc.`}
          icon="/static/icons/smartphone-icon.svg"
        />
      </div>
      <div className="col">
        <CardInfo
          text="Si tienes alguna duda o estás interesado en algún lote, contacta al vendedor en el formulario de cada proyecto."
          icon="/static/icons/booking-icon.svg"
        />
      </div>
    </div>
  );
}
