/** Gets the list of regions corresponding to the zone with `id` equals to `zoneId`. */
export async function getZoneRegions(zoneId: string) {
  const zoneIdIsInteger = /^\d+$/.test(zoneId);

  if (!zoneIdIsInteger) {
    throw new Error('zoneId is not a positive integer.');
  }

  const regions = await fetch(`/regions/regions/search/?zoneId=${zoneId}`);

  return await regions.json() as { id: number, text: string }[];
}

/** Gets the list of communes corresponding to the region with `id` equals to `regionId`. */
export async function getRegionCommunes(regionId: string) {
  const regionIdIsInteger = /^\d+$/.test(regionId);

  if (!regionIdIsInteger) {
    throw new Error('regionId is not a positive integer.');
  }

  const communes = await fetch(`/regions/communes/search/?regionId=${regionId}`);

  return await communes.json() as { id: number, text: string }[];
}
