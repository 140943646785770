import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getZoneRegions, getRegionCommunes } from '../../behaviors/regions';
import { FormInterface, InputChangeEvent, InputProps } from '../../utils/types';
import { inputGenerator } from '../inputs';
import { findNotNullField, filterChoices } from './utils';

export interface FiltersFormProps extends Omit<FormInterface, 'method'> {
  fields: Array<InputProps>,
  formRef: React.MutableRefObject<HTMLFormElement | null>,
  visible: boolean,
  onSubmit: (e: React.FormEvent) => void
}

type FieldsTypes = 'location' | 'price' | 'delivery' | 'access' | 'services';

export function FiltersForm({
  action,
  fields,
  formRef,
  visible,
  onSubmit
}: FiltersFormProps) {
  // const formRef = useRef<HTMLFormElement | null>(null);
  const [formKey, setFormKey] = useState<string | null>(null);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string | undefined }>({});

  // available*: a set with ids of the available ones, or undefined if all should be available.
  const [availableRegions, setAvailableRegions] = useState<Set<number>>();
  const [availableCommunes, setAvailableCommunes] = useState<Set<number>>();

  async function constrainRegionChoices(zoneId: string) {
    if (!zoneId) {
      setAvailableRegions(undefined);
      setAvailableCommunes(undefined);
      return;
    }

    try {
      const regions = await getZoneRegions(zoneId);
      const regionIds = regions.map((r) => r.id);
      setAvailableRegions(new Set(regionIds));

      const communesPromises = Promise.all(regionIds.map((regionId) => (
        getRegionCommunes(regionId.toString()).then((communes) => communes.map((c) => c.id))
      )));

      await communesPromises.then((communes) => {
        const communeIds = communes.reduce((previous, current) => previous.concat(current), []);
        setAvailableCommunes(new Set(communeIds));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error constraining regions', e);
    }
  }

  async function constrainCommuneChoices(regionId: string) {
    if (!regionId) {
      setAvailableCommunes(undefined);
      return;
    }

    try {
      const communes = await getRegionCommunes(regionId);
      const communeIds = communes.map((c) => c.id);
      setAvailableCommunes(new Set(communeIds));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error constraining communes', e);
    }
  }

  const handleChange = (fieldName: string) => (e: InputChangeEvent) => {
    const value = e.currentTarget.value as string;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value
    }));

    if (fieldName === 'zone') {
      void constrainRegionChoices(value);
      // Note: this is not very robust.
      // Selecting two regions quickly with slow internet could cause glitches.

      setFieldValues((prevValues) => ({
        ...prevValues,
        // deselect region and commune:
        region: undefined,
        commune: undefined
      }));
    } else if (fieldName === 'region') {
      void constrainCommuneChoices(value);

      setFieldValues((prevValues) => ({
        ...prevValues,
        commune: undefined // deselect commune
      }));
    }
  };

  const getFields = (fieldsType: FieldsTypes) => {
    const fieldsTypes = {
      location: ['zone', 'region', 'commune'],
      price: ['price_range'],
      delivery: ['delivery'],
      access: ['lake_access', 'river_access', 'car_access', 'beach_distance_range', 'town_distance_range'],
      services: ['water_access', 'electricity_access', 'internet_access']
    };

    const fieldsToShow = fieldsTypes[fieldsType];
    let fieldsData = fields.filter((field) => fieldsToShow.includes(field.name));

    if (fieldsType === 'location') {
      fieldsData = [
        findNotNullField(fieldsData, 'zone'),
        findNotNullField(fieldsData, 'region'),
        findNotNullField(fieldsData, 'commune')
      ];
    }

    return fieldsData.map((field) => {
      switch (field.type) {
        case 'range': {
          // handles state internally
          const fieldData = {
            ...field,
            extra_data: {
              ...field.extra_data,
              renderTooltip: visible
            }
          };
          return (
            <Col className="col-12" key={fieldData.name}>
              <div className="mb-3">
                {inputGenerator(fieldData)}
              </div>
            </Col>
          );
        }

        case 'select': {
          const fieldData = {
            ...field,
            placeholder: 'Seleccionar',
            value: fieldValues[field.name],
            onChange: handleChange(field.name)
          };

          if (fieldData.name === 'region') {
            filterChoices(fieldData, availableRegions);
          } else if (fieldData.name === 'commune') {
            filterChoices(fieldData, availableCommunes);
          }

          return (
            <Col md={4} key={fieldData.name}>
              <div className="mb-3">
                {inputGenerator(fieldData)}
              </div>
            </Col>
          );
        }
        default:
          // eslint-disable-next-line no-console
          console.error(`Field of type ${field.type} is not implemented here`);
          return null;
      }
    });
  };

  const resetForm = () => {
    if (formRef.current) {
      setFormKey(new Date().getTime().toString());
      setFieldValues({});
      setAvailableRegions(undefined);
      setAvailableCommunes(undefined);
    }
  };

  return (
    <form action={action} onSubmit={onSubmit} ref={formRef} key={formKey}>
      <h4>Ubicación</h4>
      <Row>
        {getFields('location')}
        <h4>Valor</h4>
        {getFields('price')}
        <h4>Entrega</h4>
        {getFields('delivery')}
        <h4>Accesos</h4>
        {getFields('access')}
        <h4>Servicios Básicos</h4>
        {getFields('services')}
      </Row>
      <div className="d-flex flex-column flex-md-row-reverse mb-7 mb-md-0">
        <button type="button" className="btn btn-outline-dark me-md-auto" onClick={resetForm}>
          Limpiar
        </button>
        <button type="submit" className="btn btn-primary mb-9 mb-md-0 mt-5 mt-md-0 me-md-3 js-do-not-disable-on-submit">
          Filtrar
        </button>
      </div>
    </form>
  );
}
