import React, { ReactNode } from 'react';

interface ErrorNoRenderViewProps {
  children: ReactNode
}

export function ErrorNoRenderView({ children }: ErrorNoRenderViewProps) {
  return (
    <div className="container">
      <a href="/">Ir a la vista principal</a>
      {children}
    </div>
  );
}
