import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useIntersection } from 'react-use';
import qs from 'qs';
import { CardCTA } from '../components/CardCTA';
import { Filters, FiltersPropsFactory, FiltersProps } from '../components/filters/Filters';
import { ProjectCard } from '../components/ProjectCard';
import { ProjectDetailsInterface, ProjectListResponse } from '../utils/types';

export const ROOT_PROJECTS_LIST_IDENTIFIER = '.react-root-projects-list-view';

export interface ProjecstListViewProps {
  contact_form: FiltersProps
}

export const ProjectsListViewFactory = (): ProjecstListViewProps => ({
  contact_form: { ...FiltersPropsFactory() }
});

export function ProjectsListView({ contact_form }: ProjecstListViewProps) {
  const [projects, setProjects] = useState<ProjectDetailsInterface[]>();
  const [shouldFetchMoreProjects, setshouldFetchMoreProjects] = useState(false);
  const [fetchNextProjectsUrl, setFetchNextProjectsUrl] = useState<ProjectListResponse['next']>(null);
  const showMoreProjectsTriggerRef = useRef<HTMLDivElement | null>(null);
  const intersection = useIntersection(showMoreProjectsTriggerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });

  const getProjects = async (queryString = '', customPath = '') => {
    const path = customPath || `${contact_form.action}?${queryString}`;
    const res = await fetch(path, {
      method: contact_form.method,
      headers: { 'Content-Type': 'application/json' }
    });

    return res;
  };

  useEffect(() => {
    (async () => {
      const initialSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(initialSearchParams.entries());
      const dataToSend = qs.stringify(params);

      const res = await getProjects(dataToSend.toString());

      if (res.ok) {
        const data = await res.json() as ProjectListResponse;

        setProjects(data.results);
        setFetchNextProjectsUrl(data.next);
      } else {
        throw new Error('There has been an error');
      }
    })().catch((err) => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    if (!intersection || !projects) {
      return;
    }

    if (projects.length > 0 && intersection?.intersectionRatio >= 1 && fetchNextProjectsUrl) {
      setshouldFetchMoreProjects(true);
      getProjects('', fetchNextProjectsUrl)
        .then((res) => res.json())
        .then((data: ProjectListResponse) => {
          setProjects([...projects, ...data.results]);
          setFetchNextProjectsUrl(data.next);
          setshouldFetchMoreProjects(false);
        })
        .catch((err) => console.error(err));
    }
  }, [intersection?.intersectionRatio]);

  const handleFiltersResult = (response: ProjectListResponse | null) => {
    if (response) {
      setProjects(response.results);
      setFetchNextProjectsUrl(response.next);
    } else {
      setProjects([]);
      setFetchNextProjectsUrl(null);
    }
    setshouldFetchMoreProjects(false);
  };

  const handleFiltersLoad = () => {
    setshouldFetchMoreProjects(true);
  };

  const generateProjectsCards = () => {
    if (!projects || projects.length === 0) {
      return <p className="text-center fw-bold">No hay proyectos disponibles</p>;
    }

    return projects.map((project) => (
      <Col md={6} lg={4} xl={3} key={project.id} data-number={project.id} className="d-flex justify-content-center">
        <div className="d-flex justify-content-center mb-6 w-100">
          <ProjectCard project={project} />
        </div>
      </Col>
    ));
  };

  return (
    <div className="pt-5 pb-7">
      <h1 className="text-center mb-6">Proyectos</h1>
      <div className="mb-6 mb-md-7">
        <Filters
          action={contact_form.action}
          method={contact_form.method}
          fields={contact_form.fields}
          onSearch={handleFiltersResult}
          onLoading={handleFiltersLoad}
        />
      </div>
      <Row>
        {projects ? generateProjectsCards() : <p className="text-center fw-bold">Cargando...</p>}
      </Row>
      <div className="d-flex flex-column align-items-center" ref={showMoreProjectsTriggerRef}>
        {shouldFetchMoreProjects
          && (
            <>
              <p className="text-center fw-semibold mb-2">
                Buscando más proyectos...
              </p>
              <Spinner animation="border" role="status" />
            </>
          )}
      </div>
      <div className="pt-3 pt-md-6">
        <CardCTA />
      </div>
    </div>
  );
}
