/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import Slider from 'rc-slider';
import type { SliderProps } from 'rc-slider';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';
import { formatNumberWithThousandSeparator } from '../../utils/functions';

function HandleTooltip({
  value,
  children,
  visible,
  renderTooltip,
  max,
  tipFormatter = (val) => `${formatNumberWithThousandSeparator(val.toString())}`,
  container
}: {
  value: number;
  children: React.ReactElement;
  visible: boolean;
  renderTooltip: boolean;
  max: number | undefined,
  tipFormatter?: (value: number) => React.ReactNode;
  container: HTMLDivElement | null;
}) {
  const tooltipRef = React.useRef<any>();
  const rafRef = React.useRef<number | null>(null);

  const cancelKeepAlign = () => {
    raf.cancel(rafRef.current!);
  };

  const keepAlign = () => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  };

  useEffect(() => {
    const element = document.getElementById('filter-modal');
    if (element) {
      element?.addEventListener('scroll', () => keepAlign());
    }

    return cancelKeepAlign;
  }, []);

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  if (!renderTooltip) {
    // Unfortunately <Tooltip>s add html at the bottom of the body,
    // so are shown even if this component is inside a "d-none".
    // Workaround: don't render Tooltip:
    return children;
    // "return" down here because useEffect must be called unconditionally
  }

  const getPlacement = () => {
    if (max && value > max / 2) {
      return 'bottomRight';
    } if (max && value < max / 2) {
      return 'bottomLeft';
    }
    return 'bottom';
  };

  if (container) {
    return (
      <Tooltip
        placement={getPlacement()}
        overlay={tipFormatter(value)}
        getTooltipContainer={() => container}
        overlayInnerStyle={{
          minHeight: 'auto',
          backgroundColor: 'white',
          color: 'black',
          boxShadow:
          'none',
          padding: 0,
          fontSize: '1rem'
        }}
        ref={tooltipRef}
        visible
      >
        {children}
      </Tooltip>
    );
  }

  return null;
}

export const handleRenderer = (
  renderTooltip: boolean, max: number, container: HTMLDivElement | null) => {
  const handleRender: SliderProps['handleRender'] = (node, props) => (
    <HandleTooltip
      max={max}
      value={props.value}
      visible={props.dragging}
      renderTooltip={renderTooltip}
      container={container}
    >
      {node}
    </HandleTooltip>
  );
  return handleRender;
};

interface TooltipSliderProps extends SliderProps {
  tipFormatter?: (value: number) => React.ReactNode;
  renderTooltip: boolean;
  container: HTMLDivElement | null;
}

export function TooltipSlider({
  tipFormatter,
  range,
  draggableTrack,
  defaultValue,
  min,
  max,
  step,
  renderTooltip,
  onChange,
  container
}: TooltipSliderProps) {
  const tipHandleRender: SliderProps['handleRender'] = (node, handleProps) => (
    <HandleTooltip
      value={handleProps.value}
      visible={handleProps.dragging}
      renderTooltip={renderTooltip}
      tipFormatter={tipFormatter}
      max={max}
      container={container}
    >
      {node}
    </HandleTooltip>
  );

  return (
    <Slider
      range={range}
      draggableTrack={draggableTrack}
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      handleRender={tipHandleRender}
    />
  );
}
