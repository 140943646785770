import React from 'react';
import { Card } from 'react-bootstrap';
import { QuoteProjectForm, QuoteProjectFormProps, QuoteProjectFormPropsFactory } from '../components/QuoteProjectForm';
import { ProjectDetailsViewPropsFactory } from './ProjectDetailsView';
import { ProjectDetailsInterface } from '../utils/types';

export const ROOT_PROJECT_QUOTATION_IDENTIFIER = '.react-root-project-quotation-view';

export interface ProjectQuotationViewProps {
  project: ProjectDetailsInterface,
  contact_form: QuoteProjectFormProps
}

export const ProjectQuotationViewFactory = (): ProjectQuotationViewProps => ({
  project: { ...ProjectDetailsViewPropsFactory().project },
  contact_form: { ...QuoteProjectFormPropsFactory() }
});

export function ProjectQuotationView({ project, contact_form }: ProjectQuotationViewProps) {
  return (
    <div className="pt-5 pb-7">
      <Card>
        <Card.Body>
          <QuoteProjectForm
            projectName={project.name}
            projectPrice={project.price}
            projectRegion={project.region}
            commune={project.commune}
            action={contact_form.action}
            fields={contact_form.fields}
            cancel_url={contact_form.cancel_url}
            method={contact_form.method}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
