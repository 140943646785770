import React from 'react';
import { Card } from 'react-bootstrap';

export function CardCTA() {
  return (
    <Card className="shadow-none">
      <Card.Body className="text-center">
        <div className="text-center mb-3 px-4">
          <h4 className="fw-semibold mb-0">¿Quieres estar en nuestra web?</h4>
          <p className="mb-0 h4 fw-normal">Contáctanos y publica tu proyecto.</p>
        </div>
        <a href="/projects/create/" className="btn btn-outline-dark d-block d-md-inline-block">
          Contáctanos
        </a>
      </Card.Body>
    </Card>
  );
}
