import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormProps, useForm } from '../hooks/useForm';
import { useAlert } from '../providers/AlertsProvider';
import { useUser } from '../providers/UserProvider';
import { ObjectOfUnknownKeys } from '../utils/types';

export const ROOT_USER_EDIT_IDENTIFIER = '.react-root-user-edit-view';

export interface UserEditViewProps {
  contact_form: FormProps
}

export const UserEditPropsFactory = (): UserEditViewProps => ({
  contact_form: {
    action: '',
    fields: [],
    method: ''
  }
});

export function UserEditView({ contact_form }: UserEditViewProps) {
  const { displayAlert } = useAlert();
  const { initials } = useUser();

  const customSubmit = (formData: ObjectOfUnknownKeys, crsfToken: string) => {
    const formDataMultiPart = new FormData();
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      formDataMultiPart.append(key, formData[key] as string);
    });

    const res = fetch(contact_form.action, {
      credentials: 'include',
      method: contact_form.method,
      headers: {
        'X-CSRFToken': crsfToken
      },
      body: formDataMultiPart
    });

    return res;
  };

  const {
    disableSubmit,
    getFields,
    onSubmit,
    showErrorMessage,
    setShowErrorMessage,
    showSuccessMessage,
    submitBtnRef
  } = useForm({
    action: contact_form.action,
    fields: contact_form.fields,
    method: contact_form.method,
    customSubmit
  });
  useEffect(() => {
    if (showErrorMessage) {
      displayAlert([{
        type: 'danger',
        message: 'Error al enviar la solicitud. Por favor inténtelo nuevamente.'
      }]);
      setShowErrorMessage(false);
    }
  }, [showErrorMessage]);

  useEffect(() => {
    if (showSuccessMessage) {
      displayAlert([{
        type: 'success',
        message: 'Su datos han sido modificados correctamente.'
      }]);
    }
  }, [showSuccessMessage]);

  const goBack = () => {
    window.history.back();
  };

  const fieldsWithSubtitles = () => {
    const fields = getFields();
    fields.splice(0, 0, (<h2 className="h5 mb-3" key="field-info-title">Información Personal</h2>));
    fields.splice(5, 0, (<h2 className="h5 mb-3" key="field-password-title">Contraseña</h2>));
    return fields;
  };

  return (
    <div className="row">
      <div className="d-none d-md-block col-lg-3" />
      <div className="pt-6 pb-7 col-12 col-lg-6">
        <div className="d-flex align-items-center mb-5 d-md-none">
          <button type="button" className="btn btn-icon me-3" onClick={goBack}>
            <span className="material-symbols-outlined">
              arrow_back
            </span>
          </button>
          <h1 className="h2 fw-semibold mb-0 ms-md-auto">
            Editar perfil
          </h1>
          {/* Invisible element to center the title */}
          <span className="d-none d-md-block ms-md-auto" />
        </div>

        <div
          className="center-all rounded-circle bg-dark text-white fs-1 mx-auto"
          style={{
            width: '100px', height: '100px'
          }}
        >
          {initials}
        </div>

        <Card className="mt-6">
          <Card.Body>
            <form onSubmit={onSubmit} autoComplete="off">
              {fieldsWithSubtitles()}
              <div className="d-md-inline-block">
                <button
                  type="submit"
                  className="btn btn-primary w-100 d-md-inline me-3 fw-medium"
                  disabled={disableSubmit}
                  ref={submitBtnRef}
                >
                  Guardar cambios
                </button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
      <div className="d-none d-md-block col-lg-3" />
    </div>
  );
}
