import React from 'react';
import { Alert as BsAlert } from 'react-bootstrap';
import { AlertProps } from '../utils/types';
import { useAlert } from '../providers/AlertsProvider';

interface Icons {
  'danger': string,
  'success': string,
  'warning': string
}

export function Alert({
  type,
  message,
  showIcon = false,
  linkText,
  linkUrl
}: AlertProps) {
  const { hideAlert } = useAlert();
  const icons: Icons = {
    danger: 'error_outline',
    success: 'check_circle',
    warning: 'warning_amber'
  };

  return (
    <BsAlert
      variant={type}
      className="d-flex sticky-top-below-navbar"
      dismissible
      onClose={hideAlert}
      style={{ zIndex: '1090' }} // To force alerts to have the highest z index between elements
    >
      {showIcon
        && icons[type as keyof typeof icons]
        && (
          <span className="icon material-symbols-outlined me-1">
            {icons[type as keyof typeof icons]}
          </span>
        )}
      <p className="mb-0">
        {message}
        {' '}
        {linkUrl && linkText && <a href={linkUrl} className="text-dark fw-semibold">{linkText}</a>}
      </p>
    </BsAlert>
  );
}
