import React, { useRef, useState } from 'react';
import qs from 'qs';
import { FormInterface, InputChangeEvent, ObjectOfUnknownKeys } from '../utils/types';
import { inputGenerator } from './inputs';
import { getRegionCommunes } from '../behaviors/regions';
import { findNotNullField, filterChoices } from './filters/utils';

export const LocationSearchBoxPropsFactory = (): FormInterface => ({
  action: '',
  fields: [],
  method: ''
});

export function LocationSearchBox({ action, fields, method }: FormInterface) {
  const formRef = useRef(null);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string | undefined }>({});

  // availableCommunes: a set with ids of the available ones, or undefined if all should be
  // available.
  const [availableCommunes, setAvailableCommunes] = useState<Set<number>>();

  async function constrainCommuneChoices(regionId: string) {
    if (!regionId) {
      setAvailableCommunes(undefined);
      return;
    }

    try {
      const communes = await getRegionCommunes(regionId);
      const communeIds = communes.map((c) => c.id);
      setAvailableCommunes(new Set(communeIds));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error constraining communes', e);
    }
  }

  const handleChange = (fieldName: string) => (e: InputChangeEvent) => {
    const value = e.currentTarget.value as string;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value
    }));

    if (fieldName === 'region') {
      void constrainCommuneChoices(value);
      // Note: this is not very robust.
      // Selecting two regions quickly with slow internet could cause glitches.

      setFieldValues((prevValues) => ({
        ...prevValues,
        commune: undefined // deselect commune
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const queryObj = {} as ObjectOfUnknownKeys;
      formData.forEach((value, key) => {
        queryObj[key] = value;
      });

      const basePath = window.location.origin;
      const queryParams = qs.stringify(queryObj);
      window.location.href = `${basePath}/projects/?${queryParams}`;
    }
  };

  function renderFields() {
    const sortedFields = [
      findNotNullField(fields, 'region'),
      findNotNullField(fields, 'commune')
    ];

    return sortedFields.map((field) => {
      const fieldData = {
        ...field,
        placeholder: 'Seleccionar',
        value: fieldValues[field.name],
        onChange: handleChange(field.name)
      };

      if (fieldData.name === 'commune') {
        filterChoices(fieldData, availableCommunes);
      }

      return (
        <div className="form-location-input-group" key={`select-field-${fieldData.name}`}>
          {inputGenerator(fieldData)}
        </div>
      );
    });
  }

  return (
    <div className="card card-search-box">
      <div className="card-body">
        <form action={action} method={method} className="form-location-box" onSubmit={handleSubmit} ref={formRef}>
          {renderFields()}
          <button className="btn btn-outline-dark" type="submit">
            Buscar
          </button>
        </form>
      </div>
    </div>
  );
}
