import React from 'react';

export interface NavLinkProps {
  path: string,
  text: string
  checkActive?: boolean,
  btnStyle?: boolean,
  iconStyle?: boolean
}
export function NavLink({
  path, text, checkActive = false, btnStyle = false, iconStyle = false
}: NavLinkProps) {
  const getActiveClass = () => {
    const { pathname }: { pathname: string } = window.location;
    const firstLevelLocation = pathname.split('/')[1];
    const isCurrentPage = path.split('/')[1] === firstLevelLocation;
    return isCurrentPage ? 'active' : '';
  };

  const getClasses = () => {
    const baseClasses = btnStyle ? 'btn btn-primary' : 'nav-link px-lg-3';
    return checkActive ? `${baseClasses}  ${getActiveClass()}` : baseClasses;
  };

  return (
    iconStyle
      ? (
        <li className="nav-item ms-2">
          <a className="nav-link nav-link-icon" href={path}>
            <span className="material-symbols-outlined">
              account_circle
            </span>
          </a>
        </li>
      )
      : (
        <li className="nav-item">
          <a className={getClasses()} href={path}>
            {text}
          </a>
        </li>
      )
  );
}
