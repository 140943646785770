import { BaseInputProps } from '../../utils/types';

export function findNotNullField<T extends BaseInputProps>(fields: T[], fieldName: string) {
  const field = fields.find((f) => f.name === fieldName);
  if (!field) {
    throw new Error(`${fieldName} field not found`);
  }
  return field;
}

export function filterChoices<T extends BaseInputProps>(
  fieldData: T,
  availableChoices?: Set<number>
) {
  if (!availableChoices) return;
  if (!fieldData.extra_data || !Array.isArray(fieldData.extra_data.choices)) return;
  // eslint-disable-next-line no-param-reassign
  fieldData.extra_data = { ...fieldData.extra_data };

  const choicesExtraData = fieldData.extra_data as {
    choices: { name: string, value: number }[]
  };

  choicesExtraData.choices = choicesExtraData.choices.filter((c) => availableChoices.has(c.value));
}
