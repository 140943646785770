import React, { useRef, useState, useEffect } from 'react';
import { InputProps, FileChangeEvent, ObjectOfUnknownKeys } from '../../utils/types';

interface ExtraData {
  allowed_extensions: string[]
}
export interface FileInputProps extends Omit<InputProps, 'extra_data'> {
  extra_data: ExtraData | ObjectOfUnknownKeys,
}

export function InputFile({
  type, name, id, placeholder = '', label = '', onChange, value, help_text, extra_data
}: FileInputProps) {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState({
    show: false,
    message: ''
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleClick = () => {
    inputFile.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const fileObject:FileChangeEvent = {
        currentTarget: {
          name,
          value: file
        }
      };
      const fileType = fileObject.currentTarget.value.type.split('/');
      const fileSize = fileObject.currentTarget.value.size;
      if (fileType[0] === 'image' && (fileSize / 1000000 > 5)) {
        setError({
          show: true,
          message: 'La imagen debe pesar máximo 5mb'
        });
        setInputValue('');
        onChange({
          currentTarget: {
            name: fileObject.currentTarget.name,
            value: ''
          }
        } as React.ChangeEvent<HTMLInputElement>);
      } else {
        setError({
          show: false,
          message: ''
        });
        onChange(fileObject);
        setInputValue(file.name);
      }
    }
  };

  return (
    <div>
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <input
        readOnly
        name={name}
        id={id}
        placeholder={placeholder}
        className="form-control bg-white input-file"
        value={inputValue}
        onClick={handleClick}
      />
      <input
        type={type}
        name="File"
        className="d-none"
        ref={inputFile}
        onChange={handleChange}
        accept={(extra_data.allowed_extensions as string[]).map((extension) => `.${extension}`).join(',')}
      />
      {error.show && (
        <span className="d-block text-danger">{error.message}</span>
      )}
      <span className="text-muted">{help_text}</span>
    </div>
  );
}
