import React from 'react';
import { ProjectDetailsInterface } from '../utils/types';
import { ProjectCardGrid } from '../components/ProjectCardGrid';

export const ROOT_USER_FAVOURITES_IDENTIFIER = '.react-root-user-favourite-view';

export interface UserFavouritesViewProps {
  object_list: ProjectDetailsInterface[]
}

export const UserFavouritesViewPropsFactory = (): UserFavouritesViewProps => ({
  object_list: []
});
export function UserFavouritesView({ object_list }: UserFavouritesViewProps) {
  return (
    <div className="pt-6 pb-3">
      <div className="d-flex d-md-block mb-6 mb-md-7">
        <a href="/accounts/profile" className="me-3 d-block d-md-none btn btn-icon">
          <span className="icon fs-3 material-symbols-outlined">
            arrow_back
          </span>
        </a>
        <h1 className="text-md-center fw-semibold">Favoritos</h1>
      </div>
      {
        object_list.length > 0
          ? <ProjectCardGrid projects={object_list} showFavoriteButton />
          : <p className="text-center">No hay favoritos para mostrar.</p>
      }
    </div>
  );
}
