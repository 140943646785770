import React, { ReactNode } from 'react';
import { AlertsProvider } from '../providers/AlertsProvider';
import { Layout } from '../layout';
import { UserProvider } from '../providers/UserProvider';
import { FormInterface } from '../utils/types';
import { SubscriptionFormProvider } from '../providers/SubscriptionFormProvider';
import { GoogleProvider } from '../providers/GoogleProvider';

export const ROOT_APP_IDENTIFIER = '.react-root-app';

// TODO: Handle google props when needed
interface AppProps {
  user_is_authenticated: boolean,
  google_maps_key: string,
  user_first_name: string,
  user_last_name: string,
  user_id: string | null,
  subscription_form: FormInterface,
  children: ReactNode
}

export const AppPropsFactory = (): Omit<AppProps, 'children'> => ({
  user_is_authenticated: false,
  google_maps_key: '',
  user_first_name: '',
  user_last_name: '',
  user_id: null,
  subscription_form: {
    action: '',
    fields: [],
    method: ''
  }
});

export function App({
  children,
  user_is_authenticated,
  user_first_name,
  user_last_name,
  subscription_form,
  google_maps_key,
  user_id
}: AppProps) {
  return (
    <GoogleProvider mapsKey={google_maps_key}>
      <UserProvider
        firstName={user_first_name}
        lastName={user_last_name}
        isAuthenticated={user_is_authenticated}
        userId={user_id}
      >
        <AlertsProvider>
          <SubscriptionFormProvider form={subscription_form}>
            <Layout>
              {children}
            </Layout>
          </SubscriptionFormProvider>
        </AlertsProvider>
      </UserProvider>
    </GoogleProvider>
  );
}
