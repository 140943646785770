import React, { useState } from 'react';
import { useMobile } from '../../utils/hooks';
import { KrpanoFullSize } from './KrpanoFullSize';

type Embedpano = {
  xml: string,
  target: HTMLDivElement,
  bgcolor: string,
};

declare global {
  interface Window {
    embedpano?: ({ xml, target, bgcolor }: Embedpano) => void
  }
}

export interface KrpanoProps {
  panoUrl?: string
}

export function KrpanoBox({ panoUrl = '' }: KrpanoProps) {
  const isMobile = useMobile();
  const [showFullScreen, setShowFullScreen] = useState(false);

  const openFullSize = () => {
    setShowFullScreen(true);
  };

  const closeFullSize = () => {
    setShowFullScreen(false);
  };

  return (
    <>
      {showFullScreen && isMobile && <KrpanoFullSize onClose={closeFullSize} panoUrl={panoUrl} />}
      <div className="krpano-box">
        {isMobile
          && (
            <div className="krpano-overlay" onClick={openFullSize} role="none">
              <button type="button" className="btn btn-dark text-white d-flex align-items-center">
                Ver experiencia 360
                <span className="material-symbols-outlined ms-2">
                  360
                </span>
              </button>
            </div>
          )}
        <iframe
          loading="lazy"
          className="pano"
          id="recorridocostacuraco"
          title="Krpnao"
          width="auto"
          height="600"
          src={panoUrl || 'https://lanube360.com/tour-costacuraco/'}
          allow="fullscreen"
        />
      </div>
    </>
  );
}
