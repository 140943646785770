import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useGoogle } from '../providers/GoogleProvider';

export interface MapProps {
  latitude: number,
  longitude: number
}

export function Map({ latitude, longitude }: MapProps) {
  const { mapsKey } = useGoogle();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapsKey
  });

  return (isLoaded ? (
    <GoogleMap
      mapContainerClassName="map-container"
      center={{ lat: Number(latitude), lng: Number(longitude) }}
      zoom={10}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: true
      }}
    >
      <Marker position={{ lat: Number(latitude), lng: Number(longitude) }} />
    </GoogleMap>
  ) : <>Error cargando el mapa, por favor recargue la página</>);
}
