import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProjectDetailsInterface } from '../utils/types';
import { ProjectCard } from './ProjectCard';

interface ProjectCardGridProps {
  projects: ProjectDetailsInterface[],
  maxProjects?: number,
  xlWidthByCard?: number
  showFavoriteButton?: boolean | false
}

export function ProjectCardGrid({
  projects, maxProjects, xlWidthByCard, showFavoriteButton
}: ProjectCardGridProps) {
  return (
    <Row className="gx-6">
      {projects.map((project, index) => {
        if (maxProjects) {
          if (index < maxProjects) {
            return (
              <Col md={6} lg={4} xl={xlWidthByCard || 3} key={`project-card-grid-item-${project.id}`} className="mb-6 d-flex justify-content-center">
                <ProjectCard project={project} showFavoriteButton={showFavoriteButton} />
              </Col>
            );
          }
          return null;
        }
        return (
          <Col key={project.id} md={6} lg={4} xl={xlWidthByCard || 3} className="mb-6 d-flex justify-content-center">
            <ProjectCard project={project} showFavoriteButton={showFavoriteButton} />
          </Col>
        );
      })}
    </Row>
  );
}
