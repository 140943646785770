import React, { PropsWithChildren } from 'react';

import { createSafeContext } from '../utils/useSafeContext';

export interface GoogleConsumerProps extends PropsWithChildren {
  mapsKey: string
}

const [useContext, Provider] = createSafeContext<GoogleConsumerProps>();

export function GoogleProvider({
  children, mapsKey
}: GoogleConsumerProps) {
  const providerValues: GoogleConsumerProps = {
    mapsKey
  };

  return (
    <Provider value={providerValues}>
      {children}
    </Provider>
  );
}

export const useGoogle = useContext;
