import React, { ReactNode } from 'react';
import { Alert } from '../components/Alert';
import { useAlert } from '../providers/AlertsProvider';
import { Footer } from '../components/Footer';
import { Navbar } from '../components/Navbar';

interface LayoutProps {
  children: ReactNode
}

export function Layout({ children }: LayoutProps) {
  const { showAlert, alertData } = useAlert();

  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <div className="container">
          {showAlert && alertData
            && (
              alertData.map((data) => (
                <Alert
                  type={data.type}
                  message={data.message}
                  showIcon={data.showIcon}
                  linkText={data.linkText}
                  linkUrl={data.linkUrl}
                />
              ))
            )}
          {children}
        </div>
      </main>
      <Footer />
    </>
  );
}
