import React from 'react';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function ImageSlide({ url }: { url?: string }) {
  if (!url) {
    return (
      <div className="image-slide image-slide-placeholder">
        No hay imágenes disponibles
      </div>
    );
  }

  return (
    <div className="image-slide">
      <img src={url} alt="" />
    </div>
  );
}

export interface ImagesSliderProps {
  images: string[]
}

export function ImagesSlider({ images }: ImagesSliderProps) {
  return (
    <Swiper
      pagination={{
        clickable: true
      }}
      navigation
      modules={[Pagination, Navigation]}
      className="swiper-image-slider"
    >
      {images.length === 0 ? (
        <SwiperSlide style={{ maxWidth: 'unset' }}>
          <ImageSlide />
        </SwiperSlide>
      ) : (
        images.map((imageUrl) => (
          <SwiperSlide style={{ maxWidth: 'unset' }} key={`image-slide-${imageUrl}`}>
            <ImageSlide url={imageUrl} />
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );
}
