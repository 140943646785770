import React from 'react';
import { InputProps } from '../../utils/types';
import { InputCaptcha } from './InputCaptcha';
import { InputCheckbox } from './InputCheckbox';
import { InputText } from './InputText';
import { RangeInput, RangeInputProps } from './RangeInput';
import { SelectInput, SelectInputProps } from './SelectInput';
import { InputFile, FileInputProps } from './InputFile';
import { Textarea } from './Textarea';

interface InputComponentsInterface {
  'recaptcha-v2': React.FC<InputProps>,
  select: React.FC<SelectInputProps>,
  range: React.FC<RangeInputProps>,
  checkbox: React.FC<InputProps>,
  file: React.FC<FileInputProps>,
  textarea: React.FC<InputProps>,
  default: React.FC<InputProps>,
}

const INPUT_COMPONENTS: InputComponentsInterface = {
  'recaptcha-v2': InputCaptcha,
  select: SelectInput,
  range: RangeInput,
  checkbox: InputCheckbox,
  file: InputFile,
  textarea: Textarea,
  default: InputText
};

type InputGenerator = Omit<InputProps, 'id'>;

export function inputGenerator({
  label,
  name,
  type,
  extra_data = {},
  value = '',
  placeholder = '',
  onChange,
  read_only = false,
  help_text = '',
  required = false
}: InputGenerator) {
  const Input = INPUT_COMPONENTS[type as keyof InputComponentsInterface]
    ? INPUT_COMPONENTS[type as keyof InputComponentsInterface]
    : INPUT_COMPONENTS.default;

  return (
    <Input
      type={type}
      id={name}
      label={label}
      name={name}
      extra_data={extra_data}
      onChange={onChange}
      value={value === null ? '' : value}
      placeholder={placeholder}
      read_only={read_only}
      help_text={help_text}
      required={required}
    />
  );
}
