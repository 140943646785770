import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useUser } from '../providers/UserProvider';
import { SubscriptionForm } from './SubscriptionForm';

export function Footer() {
  const { isAuthenticated } = useUser();
  return (
    <footer className="footer py-6">
      <div className="container">
        <Row className="row">
          <Col lg={{ order: 'last', span: 5 }}>
            <p className="d-lg-none">
              Subscríbete e infórmate de nuestros proyectos.
            </p>
            <p className="d-none d-lg-block">
              Subscríbete e infórmate de nuestros proyectos, ofertas y novedades.
            </p>
            <SubscriptionForm />
          </Col>
          <Col lg={3}>
            <div style={{ border: '2px solid #fff', borderRadius: '2px' }} className="my-5 d-lg-none" />
            <div className="d-flex align-items-center mb-4">
              <img src="/static/logo-image.png" alt="" />
              <h5 className="mb-0 fw-semibold">La Nube360</h5>
            </div>
          </Col>
          <Col lg={2}>
            <h6>Proyectos</h6>
            <ul className="navbar-nav mb-5">
              <li className="nav-item">
                <a href="/projects/" className="nav-link border-0">Lista Proyectos</a>
              </li>
              <li className="nav-item">
                <a href="/projects/create/" className="nav-link border-0">Publicar proyecto</a>
              </li>
            </ul>
          </Col>
          <Col lg={2}>
            <h6>Más información</h6>
            <ul className="navbar-nav">
              <li className="nav-item">
                {isAuthenticated
                  ? <a href="/accounts/profile/" className="nav-link border-0">Ver perfil</a>
                  : <a href="/accounts/login/" className="nav-link border-0">Iniciar sesión</a>}
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
