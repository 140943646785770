import React, { useRef } from 'react';
import { SliderProps } from 'rc-slider';
import { TooltipSlider, handleRenderer } from './TooltipSlider';
import { InputProps, ObjectOfUnknownKeys } from '../../utils/types';

interface ExtraData {
  min: number,
  max: number,
  step: number,
  renderTooltip: boolean
}

export interface RangeInputProps extends Omit<InputProps, 'extra_data'> {
  extra_data?: ObjectOfUnknownKeys | ExtraData
}

export function RangeInput({
  name,
  id,
  label = '',
  onChange,
  extra_data = { min: 0, max: 100, step: 1 } as ExtraData
}: RangeInputProps) {
  const inputMinHiddenRef = useRef<HTMLInputElement | null>(null);
  const inputContainer = useRef<HTMLDivElement | null>(null);
  const inputMaxHiddenRef = useRef<HTMLInputElement | null>(null);
  const defaultMin = (extra_data.min || 0) as number;
  const defaultMax = (extra_data.max || 100) as number;
  const defaultStep = (extra_data.step || 1) as number;
  const { renderTooltip } = extra_data as ExtraData;

  const setMinValue = (minValue: number) => {
    if (!inputMinHiddenRef || !inputMinHiddenRef.current || !Object) {
      return;
    }

    // eslint-disable-next-line
    // @ts-ignore
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
      .set.call(inputMinHiddenRef.current, minValue);
    inputMinHiddenRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const setMaxValue = (maxValue: number) => {
    if (!inputMaxHiddenRef || !inputMaxHiddenRef.current || !Object) {
      return;
    }
    // eslint-disable-next-line
    // @ts-ignore
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
      .set.call(inputMaxHiddenRef.current, maxValue);
    inputMaxHiddenRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const manuallyTriggerOnChangeEvent = (values: SliderProps['value']) => {
    if (!Object || !window.HTMLInputElement.prototype) {
      console.error('No Object avaialable');
    } else if (Array.isArray(values)) {
      setMinValue(values[0]);
      setMaxValue(values[1]);
    }
  };

  const handleChange = (values: SliderProps['value']) => {
    if (!values) {
      console.error('Error handling range input');
    } else {
      manuallyTriggerOnChangeEvent(values);
    }
  };
  return (
    <div className="mb-7" ref={inputContainer}>
      {label && <label htmlFor={name} className="form-label">{label}</label>}
      <TooltipSlider
        range
        draggableTrack
        defaultValue={[defaultMin, defaultMax]}
        min={defaultMin}
        max={defaultMax}
        step={defaultStep}
        onChange={handleChange}
        handleRender={handleRenderer(
          renderTooltip, defaultMax, inputContainer?.current
        )}
        className="input-range"
        renderTooltip={renderTooltip}
        container={inputContainer?.current}
      />
      <input
        className="d-none"
        type="test"
        name={`${name}[min]`}
        id={id}
        ref={inputMinHiddenRef}
        onChange={onChange}
      />

      <input
        className="d-none"
        type="test"
        name={`${name}[max]`}
        id={id}
        ref={inputMaxHiddenRef}
        onChange={onChange}
      />
    </div>
  );
}
