import React, { PropsWithChildren, useState } from 'react';
import { AlertProps } from '../utils/types';

import { createSafeContext } from '../utils/useSafeContext';

export interface AlertsConsumerProps {
  showAlert: boolean,
  displayAlert: (alerts: AlertProps[]) => void,
  hideAlert: () => void,
  alertData: AlertProps[] | null
}

const [useContext, Provider] = createSafeContext<AlertsConsumerProps>();

export function AlertsProvider({ children }: PropsWithChildren<unknown>) {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<AlertProps[] | null>(null);

  const hideAlert = () => {
    setShowAlert(false);
    setAlertData(null);
  };

  const displayAlert = (alerts: AlertProps[]) => {
    setAlertData(alerts);
    setShowAlert(true);

    setTimeout(hideAlert, 10000);
  };

  const providerValues: AlertsConsumerProps = {
    showAlert,
    displayAlert,
    hideAlert,
    alertData
  };

  return (
    <Provider value={providerValues}>
      {children}
    </Provider>
  );
}

export const useAlert = useContext;
