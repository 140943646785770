import React, { useState } from 'react';
import { useAlert } from '../providers/AlertsProvider';
import { useUser } from '../providers/UserProvider';

export interface FavoriteButtonProps {
  projectId: string | number,
  markFavourite: boolean
  label?: string,
}

export function FavoriteButton({ projectId, label = '', markFavourite = false }: FavoriteButtonProps) {
  const [isFavourite, setIsFavourite] = useState(markFavourite);
  const { displayAlert } = useAlert();
  const { isAuthenticated } = useUser();

  const toggleFavourite = async () => {
    const csrfToken = (document.getElementById('csrf_token') as HTMLInputElement).value;
    const endPointPath = isFavourite ? 'unset-favourite' : 'set-favourite';
    const res = await fetch(`/api/v1/projects/${projectId}/${endPointPath}/`, {
      method: 'POST',
      headers: { 'X-CSRFToken': csrfToken }
    });

    if (res.ok) {
      setIsFavourite(!isFavourite);
    } else {
      throw new Error('Error managing favourites');
    }
  };

  const handleFavouriteClick = () => {
    if (!isAuthenticated) {
      displayAlert([{
        linkUrl: `${window.location.origin}/accounts/login`,
        linkText: 'Inicia sesión acá.',
        type: 'danger',
        message: 'Para agregar a favoritos tienes que iniciar sesión.',
        showIcon: true
      }]);

      return;
    }

    toggleFavourite().catch(() => {
      displayAlert([{
        type: 'danger',
        message: 'Ocurrió un error, por favor inténtelo de nuevo.',
        showIcon: true
      }]);
    });
  };

  return (
    <button type="button" className="btn" onClick={handleFavouriteClick}>
      <span>
        {label}
      </span>
      <span className="ms-2">
        <img src={isFavourite ? '/static/icons/star_filled.svg' : '/static/icons/star_border.svg'} alt="" />
      </span>
    </button>
  );
}
