import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Slide } from '../utils/types';

export interface CarouselProps {
  slides: Slide[]
}

export function Carousel({ slides }: CarouselProps) {
  return (
    <Swiper
      navigation
      pagination={{
        clickable: true
      }}
      modules={[Pagination, Navigation, Autoplay]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      className="swiper-carousel"
    >
      {slides.map((slide) => (
        <SwiperSlide key={`slide-${slide.id}`} style={{ maxWidth: 'unset' }}>
          <div className="swiper-carousel-image" style={{ backgroundImage: `url(${slide.image})` }}>
            <h2 className="swiper-carousel-text text-center">
              {slide.text}
            </h2>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
