import React from 'react';
import { useAlert } from '../providers/AlertsProvider';
import { FavoriteButton, FavoriteButtonProps } from './FavoriteButton';

export interface FavoriteShareBarProps extends FavoriteButtonProps {
  shareDescription: string,
  shareTitle: string,
  shareUrl: string
}

export function FavoriteShareBar({
  shareDescription = '', shareTitle = '', shareUrl, projectId, markFavourite = false, label = 'Agregar a favoritos'
}: FavoriteShareBarProps) {
  const { displayAlert } = useAlert();

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      displayAlert([{
        type: 'success',
        message: 'Url copiada al portapapeles.',
        showIcon: true
      }]);
    }).catch(() => {
      displayAlert([{
        type: 'danger',
        message: 'Ocurrió un error intentando compartir, por favor inténtelo de nuevo.'
      }]);
    });
  };
  const shareProject = async () => {
    const shareData = {
      title: shareTitle,
      text: shareDescription,
      url: shareUrl
    };

    if (navigator.share && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      throw new Error('Sharing not supported');
    }
    return '';
  };

  const handleShareClick = () => {
    shareProject().catch(() => {
      copyUrlToClipboard();
    });
  };

  return (
    <div className="d-flex justify-content-between justify-content-md-center border-top border-bottom py-5 my-5">
      <FavoriteButton
        projectId={projectId}
        markFavourite={markFavourite}
        label={label}
      />

      <button type="button" className="btn d-flex rounded-circle border-dark p-2" onClick={handleShareClick}>
        <span className="material-symbols-outlined" aria-label="Compartir proyecto">
          share
        </span>
      </button>
    </div>
  );
}
