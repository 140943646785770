import React, { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { generatePopulatedObject, isAnObject } from '../utils/functions';
import { ObjectOfUnknownKeys } from '../utils/types';
import { App, AppPropsFactory, ROOT_APP_IDENTIFIER } from './App';
import { ErrorNoRenderView } from './ErrorNoRenderView';
import { HomeView, HomeViewPropsFactory, ROOT_HOME_IDENTIFIER } from './HomeView';
import {
  ProjectDetailsView,
  ProjectDetailsViewPropsFactory,
  ROOT_PROJECT_DETAILS_IDENTIFIER
} from './ProjectDetailsView';
import { ProjectQuotationView, ProjectQuotationViewFactory, ROOT_PROJECT_QUOTATION_IDENTIFIER } from './ProjectQuotationView';
import { ProjectsListView, ProjectsListViewFactory, ROOT_PROJECTS_LIST_IDENTIFIER } from './ProjectsListView';
import { UserDetailsView, USER_DETAILS_IDENTIFIER, UserDetailsViewPropsFactory } from './UserDetailsView';
import { UserProjectsView, UserProjectsViewPropsFactory, ROOT_USER_PROJECTS_IDENTIFIER } from './UserProjectsView';
import { ProjectUpdateView, ROOT_PROJECT_UPDATE_IDENTIFIER, ProjectUpdatePropsFactory } from './ProjectUpdateView';
import { ProjectCreateView, ROOT_PROJECT_CREATE_IDENTIFIER, ProjectCreatePropsFactory } from './ProjectCreateView';
import { LoginView, LoginPropsFactory, ROOT_LOGIN_IDENTIFIER } from './LoginView';
import { RegisterView, RegisterPropsFactory, ROOT_REGISTER_IDENTIFIER } from './RegisterView';
import { CreatePasswordView, CreatePasswordPropsFactory, ROOT_CREATE_PASSWORD_IDENTIFIER } from './CreatePasswordView';
import { ResetPasswordPropsFactory, ResetPasswordView, ROOT_RESET_PASSWORD_IDENTIFIER } from './ResetPasswordView';
import { ROOT_USER_EDIT_IDENTIFIER, UserEditPropsFactory, UserEditView } from './UserEditView';
import { UserFavouritesView, ROOT_USER_FAVOURITES_IDENTIFIER, UserFavouritesViewPropsFactory } from './UserFavouritesView';

interface Views {
  container: Element | null,
  Component: React.FC<any>,
  defaultProps?: ObjectOfUnknownKeys
}

const views: Array<Views> = [
  {
    container: document.querySelector(ROOT_PROJECT_DETAILS_IDENTIFIER),
    Component: ProjectDetailsView,
    defaultProps: { ...ProjectDetailsViewPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_HOME_IDENTIFIER),
    Component: HomeView,
    defaultProps: { ...HomeViewPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_PROJECTS_LIST_IDENTIFIER),
    Component: ProjectsListView,
    defaultProps: { ...ProjectsListViewFactory() }
  },
  {
    container: document.querySelector(ROOT_PROJECT_QUOTATION_IDENTIFIER),
    Component: ProjectQuotationView,
    defaultProps: { ...ProjectQuotationViewFactory() }
  },
  {
    container: document.querySelector(USER_DETAILS_IDENTIFIER),
    Component: UserDetailsView,
    defaultProps: { ...UserDetailsViewPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_USER_PROJECTS_IDENTIFIER),
    Component: UserProjectsView,
    defaultProps: { ...UserProjectsViewPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_PROJECT_UPDATE_IDENTIFIER),
    Component: ProjectUpdateView,
    defaultProps: { ...ProjectUpdatePropsFactory() }
  },
  {
    container: document.querySelector(ROOT_PROJECT_CREATE_IDENTIFIER),
    Component: ProjectCreateView,
    defaultProps: { ...ProjectCreatePropsFactory() }
  },
  {
    container: document.querySelector(ROOT_LOGIN_IDENTIFIER),
    Component: LoginView,
    defaultProps: { ...LoginPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_REGISTER_IDENTIFIER),
    Component: RegisterView,
    defaultProps: { ...RegisterPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_CREATE_PASSWORD_IDENTIFIER),
    Component: CreatePasswordView,
    defaultProps: { ...CreatePasswordPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_RESET_PASSWORD_IDENTIFIER),
    Component: ResetPasswordView,
    defaultProps: { ...ResetPasswordPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_USER_EDIT_IDENTIFIER),
    Component: UserEditView,
    defaultProps: { ...UserEditPropsFactory() }
  },
  {
    container: document.querySelector(ROOT_USER_FAVOURITES_IDENTIFIER),
    Component: UserFavouritesView,
    defaultProps: { ...UserFavouritesViewPropsFactory() }
  }
];

const getViewProps = (propsId: string, defaultProps: Views['defaultProps']) => {
  const propsContainer = document.getElementById(propsId);
  const props = { ...defaultProps };

  if (!propsContainer || !propsContainer.textContent) {
    return props;
  }

  const viewProps = JSON.parse(propsContainer.textContent) as ObjectOfUnknownKeys;
  Object.keys(props).forEach((key) => {
    // In case is a nested object prop
    if (isAnObject(props[key])) {
      const populatedProps = generatePopulatedObject(
        props[key] as ObjectOfUnknownKeys, viewProps[key] as ObjectOfUnknownKeys
      );
      props[key] = populatedProps;
    } else {
      props[key] = viewProps[key];
    }
  });

  return props;
};

const appContainer = document.querySelector(ROOT_APP_IDENTIFIER);
const viewToRender = views.find((view) => view.container !== null);
if (appContainer) {
  const appRoot = createRoot(appContainer);
  const appGlobalProps = getViewProps('react-global-props', AppPropsFactory());

  if (viewToRender) {
    const viewProps = getViewProps('react-view-props', viewToRender.defaultProps);
    // eslint-disable-next-line -- To avoid ts-ignore warning
    /* @ts-ignore */ // eslint-disable-next-line -- To avoid props spreading warning
    appRoot.render(createElement(App, { ...appGlobalProps }, <viewToRender.Component {...viewProps} />));
  } else {
    // Insert already existing html into react layout
    const oldHTML = appContainer.innerHTML;
    appRoot.render(
      createElement(ErrorNoRenderView, null, <div dangerouslySetInnerHTML={{ __html: oldHTML }} />)
    );
  }
}
