import React, { PropsWithChildren } from 'react';

import { createSafeContext } from '../utils/useSafeContext';

export interface UserProviderProps extends PropsWithChildren {
  firstName: string,
  lastName: string,
  isAuthenticated: boolean,
  userId: string | null,
}

export interface UserConsumerProps extends PropsWithChildren {
  firstName: string,
  lastName: string,
  isAuthenticated: boolean,
  initials: string,
  userId: string | null
}

const [useContext, Provider] = createSafeContext<UserConsumerProps>();

export function UserProvider({
  children, firstName, lastName, isAuthenticated, userId
}: UserProviderProps) {
  const initials = firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase() : '';

  const providerValues: UserConsumerProps = {
    firstName,
    lastName,
    isAuthenticated,
    initials,
    userId
  };

  return (
    <Provider value={providerValues}>
      {children}
    </Provider>
  );
}

export const useUser = useContext;
